import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Tabs, Tab, Typography, IconButton, Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchOSADevice, deleteOSASensor } from '../osa.service';
import { OSASensor, OSAState } from '../model';
import OSADeviceViewDetails from './OSADeviceViewDetails';
import OSADeviceViewHistory from './OSADeviceViewHistory';
import styles from './index.module.scss';
import ConfirmationDialog from '../../../shared/ui/Dialogs/ConfirmationDialog';

const initialCurrentState: OSAState = {
  availChangeTimeISO: '',
  availChangeTimeMs: 0,
  distanceChangeCount: 0,
  distanceMm: 0,
  isEmpty: false,
  updateTimeISO: '1970-01-01T00:00:00.000Z',
  updateTimeMs: 0,
  batteryCharge: 0,
  measurementIntervalSec: 0,
};

const initialDevice: OSASensor = {
  labelCode: 'No code',
  merchandise: {
    code: '',
    name: '',
  },
  positionInStore: {
    name: '',
  },
  positionEmptyDepthMm: 0,
  positionLowWarning: '20',
  currentState: initialCurrentState,
};

const OSADeviceView = (): JSX.Element => {
  const { formatMessage: i18n } = useIntl();

  const [tabIndex, setTabIndex] = useState(0);
  const [OSADevice, setOSADevice] = useState(initialDevice);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteModalPopup, setDeleteModalPopup] = useState(false);
  const { deviceSerNum } = useParams();
  const history = useHistory();
  const historyStr = i18n({ id: 'common.history' });
  const detailsStr = i18n({ id: 'common.details' });
  const confirmStr = i18n({ id: 'common.confirm' });
  const cancelStr = i18n({ id: 'common.cancel' });
  const dialogContentStr = i18n({ id: 'common.deleteConfirmationMessage' }, { name: OSADevice.merchandise?.name });
  const dialogErrorMessageStr = i18n({ id: 'common.deleteDialogErrorMessage' }, { name: 'OSA' });
  const dialogOkButtonStr = i18n({ id: 'common.ok' });

  useEffect(() => {
    fetchOSADevice(deviceSerNum).then((item) => {
      setOSADevice(item);
      setIsLoading(false);
    });
  }, [deviceSerNum]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTabIndex(newValue);
  };

  const back = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    history.goBack();
  };

  const renderOSASensor = (visibleIndex: number): JSX.Element => {
    switch (visibleIndex) {
      case 1:
        return <OSADeviceViewHistory />;
      default:
        return <OSADeviceViewDetails sensorData={OSADevice} />;
    }
  };

  const openDeleteFailureModal = (): void => {
    setDeleteModalPopup(true);
  };

  const closeDeleteFailureModal = (): void => {
    setDeleteModalPopup(false);
  };

  const handleDeleteConfirm = async (): Promise<void> => {
    setConfirmDialog(false);
    if (OSADevice.serialNum !== undefined) {
      const resultOfDelete: string = await deleteOSASensor(OSADevice.serialNum);
      if (resultOfDelete === 'OK') {
        window.location.href = '/osa';
      } else {
        openDeleteFailureModal();
      }
    }
  };

  const handleCloseDialog = (): void => {
    setConfirmDialog(false);
  };

  const handleOpenDialog = (): void => {
    setConfirmDialog(true);
  };

  return (isLoading
    ? (
      <div style={{ marginTop: '50vh' }}>
        <CircularProgress />
      </div>
    )
    : (
      <>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ width: '90%' }}
        >
          <IconButton onClick={back}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">{OSADevice.merchandise?.name}</Typography>
          <div>
            <Link
              to={{
                pathname: '/devices/scan',
                state: {
                  labelCode: OSADevice.labelCode,
                  serialNumber: OSADevice.serialNum,
                  step: 3,
                  deviceType: {
                    type: 'osa',
                    display: 'On-Shelf Availability Sensor',
                  },
                  merchandise: OSADevice.merchandise,
                  positionInStore: OSADevice.positionInStore,
                  positionEmptyDepthMm: OSADevice.positionEmptyDepthMm,
                  positionLowWarning: OSADevice.positionLowWarning,
                },
              }}
            >
              <EditIcon color="secondary" />
            </Link>
            <DeleteIcon color="secondary" id="deleteOSA" onClick={handleOpenDialog} className={styles['delete-icon']} />
          </div>
        </Box>
        <ConfirmationDialog
          open={confirmDialog}
          handleClose={handleCloseDialog}
          handleAgree={handleDeleteConfirm}
          contentText={dialogContentStr}
          cancelLabel={cancelStr}
          confirmLabel={confirmStr}
        />
        <ConfirmationDialog
          open={deleteModalPopup}
          handleAgree={closeDeleteFailureModal}
          contentText={dialogErrorMessageStr}
          confirmLabel={dialogOkButtonStr}
        />
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          variant="fullWidth"
          onChange={handleChange}
          aria-label="availability sensor Tabs"
          className={styles['tabs-container']}
        >
          <Tab data-testid="osa-details-tab" label={detailsStr} />
          <Tab disabled data-testid="osa-history-tab" label={historyStr} />
        </Tabs>
        {renderOSASensor(tabIndex)}
      </>
    )
  );
};

export default OSADeviceView;
