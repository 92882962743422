import { MenuItem, Select } from '@material-ui/core';
import React from 'react';

export interface SelectItem {
  id: number | string;
  name: number | string;
}

interface Props {
  items: SelectItem[];
  value: string;
  onChange(selectedItem: string): void;
}

export const SelectField = (props: Props): JSX.Element => {
  const { items, value, onChange } = props;
  const handleChange = (event: React.ChangeEvent<{ value: string | unknown }>): void =>
    onChange(event.target.value as string);

  return (
    <Select
      value={value}
      onChange={handleChange}
    >
      {items.map(
        (item: SelectItem) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ),
      )}
    </Select>
  );
};
