import { AxiosResponse } from 'axios';
import crypto from 'crypto';
import { setRefreshInterval, setStore, setTenant, setToken, setUser, getTenant, getRegistrationToken } from 'common/services/token.service';
import httpClient from '../../shared/services/http-client';

export const
  sendRegistrationTokenToServer = async (userName: string, registrationToken: string | null):
    Promise<void> => {
    const tenantId = getTenant();
    if (!userName || !registrationToken || !tenantId) {
      return;
    }

    const response: AxiosResponse = await httpClient.get(`/v1/model/tenants/${tenantId}/userRegistrationTokens?registrationToken=${registrationToken}`);
    if ((!response) || (!response.data) || response.data.length === 0) {
      const id = crypto.randomBytes(16).toString('hex');

      const userReg = {
        registrationToken,
        userId: userName,
        expired: false,
      };
      await httpClient.post(`/v1/model/tenants/${tenantId}/userRegistrationTokens/${id}`, userReg);
    }
  };

/**
 * Sometime due to delay in receiving registration token,
 * check until available with given delay and repetitions.
 * @param delay
 * @param repetitions
 * @param userName
 */
const setIntervalX = (delay: number, repetitions: number, userName: string): void => {
  let x = 0;

  const intervalID = window.setInterval(() => {
    const registrationToken = getRegistrationToken();
    sendRegistrationTokenToServer(userName, registrationToken);
    x += 1;
    if (x === repetitions || registrationToken) {
      window.clearInterval(intervalID);
    }
  }, delay);
};

// TODO - replace implementation with API call
export const login = async (userName: string, password: string): Promise<void> => {
  const data = {
    userName,
    password,
  };
  const response = await httpClient.post('/v1/login', data);
  setTenant(response.data.tenantId);
  setStore(response.data.storeId);
  setToken(response.data.tenantId);
  setUser(userName);
  setRefreshInterval('10000');

  setIntervalX(3000, 5, userName);
};
