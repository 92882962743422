import { getTenant } from 'common/services/token.service';

const tenantId = getTenant();

export const endpoints = {
  ALL_DEVICES_BY_SERIAL_NUMBER: `/v1/model/tenants/staging/devices/{serNum}`,
  PROVISIONED_DEVICES: `/v1/model/tenants/{tenantId}/devices?storeId={storeId}`,
  PROVISIONED_DEVICE_BY_SERIAL_NUMBER: `/v1/model/tenants/${tenantId}/devices/{serNum}`,

  MERCHANDISE_BY_CODE: `/v1/model/tenants/${tenantId}/merchandise?code={merchCode}`,
  MERCHANDISE_SEARCH_BY_CODE: `/v1/model/tenants/${tenantId}/merchandise?sort=code&startAt={merchCode}&endAt={merchCode}z`,
  MERCHANDISE_SEARCH_BY_NAME: `/v1/model/tenants/${tenantId}/merchandise?sort=name&startAt={merchName}&endAt={merchName}z`,
  MERCHANDISE_SORT_BY_NAME: `/v1/model/tenants/${tenantId}/merchandise?sort=name`,
  MERCHANDISE_BY_DEVICEUPC: `/v1/model/tenants/${tenantId}/merchandise?deviceUPC={deviceUPC}`,

  SINGLE_OSA_SENSOR_DATA: `/v1/model/tenants/${tenantId}/devices/{serNum}`,
  OSA_SENSORS_DATA: `/v1/model/tenants/{tenantId}/devices?classId=osa&storeId={storeId}`,

  POSITIONS_IN_STORE: `/v1/model/tenants/${tenantId}/positionsInStore`,
  POSITIONS_IN_STORE_SORT_BY_NAME: `/v1/model/tenants/${tenantId}/positionsInStore?sort=name`,
  POSITIONS_IN_STORE_SEARCH_BY_NAME: `/v1/model/tenants/${tenantId}/positionsInStore?sort=name&startAt={positionName}&endAt={positionName}z`,

  SINGLE_TV_SENSOR_DATA: `/v1/model/tenants/${tenantId}/devices/{serNum}`,
  TV_SENSORS_DATA: `/v1/model/tenants/{tenantId}/devices?classId=tv&storeId={storeId}`,
  TV_SENSORS_DATA_SORT_BY_POWER: `/v1/model/tenants/${tenantId}/devices?sort=currentState.isOff:desc`,

  ALL_TV_DEVICES_CONFIG: `/v1/device/config/tv/location/{storeId}`,
  SINGLE_TV_DEVICE_CONFIG: `/v1/device/config/tv/{serNum}/`,

  TV_MANUFACTURER_BY_CODE: `/v1/model/tv_manufacturers/{manufacturerCode}`,

  OSA_SENSOR_SETTING: `/v1/model/tenants/${tenantId}/defaultDeviceSettings?storeId={storeId}&type=osa`,

  BUSINESS_HOURS: `/v1/model/tenants/{tenantId}/businessHours?storeId={storeId}`,

};
