import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { OSASensor } from 'modules/osa/model';
import { ListItemErrorStatus } from 'shared/ui/device-list/list-item/model';
import {
  isOSAOutOfStock, isProvisioned, isDisconnected, matchesFilterCriteria,
  getStatusDesc, isOSALowStock, getOSAStatus, isLowStockEnabled, compTimeEmpty,
} from 'modules/osa/osa-util';
import { ListViewProps } from './model';
import OSAListView, { OSAListViewItemData } from './list-view';
import { SearchContext } from '../../../app/Context';

const OutOfStockOSA = ({ list, positionLowWarning }: ListViewProps): JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const { formatMessage: i18n } = useIntl();

  const mapOSAtoListItemData = (items: OSASensor[]): OSAListViewItemData[] => items
    .filter((item: OSASensor) => isProvisioned(item)
      && !isDisconnected(item)
      && (isOSAOutOfStock(item)
        || (isOSALowStock(item) && isLowStockEnabled(item) && positionLowWarning > 0))
      && matchesFilterCriteria(item, searchCriteria))
    .sort((a, b) => compTimeEmpty(a, b))
    .map((item: OSASensor) => {
      const status = {
        text: getOSAStatus(item),
        desc: getStatusDesc(item, i18n),
        errorStatus: ListItemErrorStatus.warning,
      };
      return {
        OSAData: item,
        status,
      };
    });

  return <OSAListView list={mapOSAtoListItemData(list)} />;
};

export default OutOfStockOSA;
