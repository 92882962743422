import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { GridColumn } from 'common/models/material-ui';
import { defaultColumns } from '../list-constants';
import { DescriptionText, ItemStatus, ListItemErrorStatus } from './model';

interface ListItemProps {
  description: DescriptionText[];
  status: ItemStatus;
  columns?: GridColumn[];
}

const getStatusColor = (errorStatus: ListItemErrorStatus | undefined, theme: Theme): string => {
  switch (errorStatus) {
    case ListItemErrorStatus.Danger:
      return theme.palette.error.main;
    case ListItemErrorStatus.warning:
      return theme.palette.warning.main;
    default:
      return '';
  }
};

const useStyle = makeStyles((theme: Theme) => createStyles({
  status: ({ status }: ListItemProps) => ({
    color: getStatusColor(status.errorStatus, theme),
  }),
  textBold: {
    fontWeight: 'bold',
  },
}));

const ListItem = (props: ListItemProps): JSX.Element => {
  const styles = useStyle(props);
  const {
    description, status, columns = defaultColumns,
  } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={columns[0]} container direction="column" alignItems="flex-start">
        {description.map((item: DescriptionText) => (
          <Typography key={item.text} variant="body1" className={item.bold ? styles.textBold : ''}>
            {item.text}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={columns[1]} className={styles.status}>
        {status.desc
          ? (
            <Typography variant="body2" align="center" style={{ whiteSpace: 'pre-wrap', textTransform: 'capitalize' }}>
              {status.desc}
            </Typography>
          ) : <></>}
        <Typography variant="body2" align="center" style={{ whiteSpace: 'pre-wrap', textTransform: 'capitalize' }}>
          {status.text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ListItem;
