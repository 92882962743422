import React, { useContext } from 'react';
import { ListItemErrorStatus } from 'shared/ui/device-list/list-item/model';
import { useIntl } from 'react-intl';
import { ListViewProps } from './model';
import { TVSensor } from '../../model';
import TVListView, { TVListViewItemData } from './list-view';
import {
  isDisconnected,
  isIssue,
  isProvisioned,
  isPowerStateUnknown,
  isLowBattery,
  matchesFilterCriteria,
} from '../../tvSensor.service';
import { SearchContext } from '../../../app/Context';

const TVIssuesList = ({ list }: ListViewProps): JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const { formatMessage: i18n } = useIntl();
  const offlineStr = i18n({ id: 'common.offline' });
  const installPendingStr = i18n({ id: 'devices.installPending' });
  const lowBatteryStr = i18n({ id: 'common.lowBattery' });
  const powerStateUnknownStr = i18n({ id: 'devices.powerStateUnknown' });
  // const noACPowerStr
  // const noHDMIConnectionStr

  // sort sensors with issues: not provisioned, not reporting (disconnected),
  // power state unknown, removed from hdmi, removed from a/c power, low battery
  // FIXME add removed from hdmi, removed from power, and low battery to compare
  const compare = (a: TVSensor, b: TVSensor): number => {
    if (!isProvisioned(a) && !isProvisioned(b)) return 0;
    if (!isProvisioned(a) && isDisconnected(b)) return -1;
    if (!isProvisioned(a) && isPowerStateUnknown(b)) return -1;
    if (isDisconnected(a) && isDisconnected(b)) return 0;
    if (isDisconnected(a) && isProvisioned(b) && isPowerStateUnknown(b)) return -1;
    if (isDisconnected(a) && !isProvisioned(b)) return 1;
    if (isPowerStateUnknown(a) && isPowerStateUnknown(b)) return 0;
    if (isPowerStateUnknown(a) && isDisconnected(b)) return 1;
    if (isPowerStateUnknown(a) && !isProvisioned(b)) return 1;
    return 0;
  };

  const mapTVtoListItemData = (items: TVSensor[]): TVListViewItemData[] => items
    .filter((item: TVSensor) => isIssue(item)
      && matchesFilterCriteria(item, searchCriteria))
    .sort((a, b) => compare(a, b))
    .map((item: TVSensor) => {
      let statusText = '';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let statusTextNumber = 0;
      if (!isProvisioned(item)) {
        statusText = installPendingStr;
      } else if (isDisconnected(item)) {
        statusText = offlineStr;
      } else {
        if (isPowerStateUnknown(item)) {
          statusText += `${powerStateUnknownStr}\n`;
          statusTextNumber += 1;
        }
        if (isLowBattery(item) && statusTextNumber < 2) {
          statusText += (`${lowBatteryStr}\n`);
          statusTextNumber += 1;
        }
        // FIXME add missing issues
        /* if (another error tye(item) && statusTextNumber < 2) {
          statusText.concat(`\n${errorFunction(value)}`)
          statusTextNumber += 1;
        } */
      }
      const status = {
        text: statusText,
        errorStatus: ListItemErrorStatus.warning,
      };
      return {
        TVData: item,
        status,
      };
    });

  return <TVListView list={mapTVtoListItemData(list)} />;
};

export default TVIssuesList;
