import firebase from 'firebase';

const getFirebaseConfig = () => {
  const fb_config = {
    apiKey: process.env.REACT_APP_API_KEY,
    projectId: process.env.REACT_APP_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_MESSAGINGID,
    appId: process.env.REACT_APP_APPID
  }
  return fb_config;
};
// Initialize Firebase
firebase.initializeApp(getFirebaseConfig());
export default firebase;