import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import './index.module.scss';
import { useIntl } from 'react-intl';
import { QuaggaJSResultObject } from '@ericblade/quagga2';
import Button from '@material-ui/core/Button';
import { getDeviceBySN, getDeviceTenant } from '../devices.service';
import ReactQuagga, { useQuagga } from './ReactQuagga';
import { Device } from '../model';

interface ScanDeviceBarcodeProps {
  nextStep: (formattedSn: string, serialNum: string) => void;
  onChange: (snValue: string) => void;
  labelCodeValue: string;
  onExistingDevice: (
    serialNum: string,
    merchCode: string,
    merchName: string,
    locName: string,
    positionEmptyDepthMm: number,
    merchDeviceUpc?: string,
    brand?: string,
    department?: string,
  ) => void;
}

const knownDeviceTypeCodes = ['01', '02', '03', '04', '05'];

const ScanDeviceBarcode = (props: ScanDeviceBarcodeProps): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const enterManuallyStr = i18n({ id: 'devices.enterManually' });
  const nextStr = i18n({ id: 'devices.next' });
  const scannerNotSupportedStr = i18n({ id: 'devices.scannerNotSupported' });
  const scannerNotActiveStr = i18n({ id: 'devices.scannerNotActive' });
  const troubleScanningStr = i18n({ id: 'devices.troubleScanning' });
  const deviceNotAvailableStr = i18n({ id: 'devices.deviceNotAvailable' });
  const invalidBarcodeStr = i18n({ id: 'devices.invalidBarcode' });
  let { labelCodeValue } = props;
  const [scannerIsActive, setScannerIsActive] = useState(true);
  const [error, setError] = useState('');
  const scannerSupported = useQuagga();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setError('');
    const newValue = e.target.value.replace(/\s/g, '').trim();
    props.onChange(newValue);
  };

  const handleScan = (data: QuaggaJSResultObject): void => {
    labelCodeValue = data.codeResult.code;
    props.onChange(labelCodeValue);
  };

  const next = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();
    const formattedSnValue = labelCodeValue.replace(/-/gi, ''); // Strip - from S/N.
    if (formattedSnValue.length !== 10) {
      setError(invalidBarcodeStr);
      return;
    }
    if (!knownDeviceTypeCodes.includes(formattedSnValue.substr(2, 2))) {
      setError(invalidBarcodeStr);
      return;
    }
    // reformat s/n before running get so format matches what's in firestore. Do not change snValue,
    // however, because user will expect to see printed format.
    const serialNum = `0E00${formattedSnValue.substr(2, 2)}${formattedSnValue.substr(0, 2)}00${formattedSnValue.substr(4)}`.toUpperCase();
    const deviceTenant = await getDeviceTenant(serialNum);
    if (deviceTenant === undefined) {
      // If tenantId is undefined, either the device exists but
      // isn't provisioned OR the device does not exist yet
      // follow process for provisioning by completing all screens
      setScannerIsActive(false);
      props.nextStep(formattedSnValue, serialNum);
    } else if (deviceTenant === sessionStorage.tenant_key) {
      setScannerIsActive(false);
      const scannedDeviceObject: Device = await getDeviceBySN(serialNum);
      if (scannedDeviceObject) {
        props.onExistingDevice(
          serialNum,
          scannedDeviceObject.merchandise?.code || '',
          scannedDeviceObject.merchandise?.name || '',
          scannedDeviceObject.positionInStore?.name || '',
          scannedDeviceObject.positionEmptyDepthMm || 300,
          scannedDeviceObject.merchandise?.deviceUPC,
          scannedDeviceObject.merchandise?.brand,
          scannedDeviceObject.positionInStore?.department,
        );
      } else {
        props.nextStep(formattedSnValue, serialNum);
      }
    } else {
      setError(deviceNotAvailableStr);
    }
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch"
    >
      <Grid item>
        <Card elevation={0}>
          <CardActionArea>
            {scannerIsActive ? (
              <ReactQuagga
                onDetected={handleScan}
              />
            ) : (
              <Typography variant="body2" color="textSecondary" component="p" align="center">
                {scannerNotActiveStr}
              </Typography>
            )}
            <CardContent>
              {scannerSupported ? <div /> : (
                <Typography variant="body2" color="textSecondary" component="p" align="center">
                  {scannerNotSupportedStr}
                </Typography>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item>
        <div style={{ position: 'fixed', bottom: '0', textAlign: 'center', backgroundColor: 'white' }}>
          <Paper component="form" elevation={0} style={{ width: '100vw' }}>
            <Typography variant="body1">
              {troubleScanningStr}
            </Typography>
            <Typography variant="body1">
              {`${enterManuallyStr}:`}
            </Typography>
            {error ? (
              <Typography style={{ color: 'red' }}>
                {error}
              </Typography>
            ) : <div />}
            <OutlinedInput
              value={labelCodeValue}
              onChange={handleChange}
              style={{ width: '80vw', height: '2rem', padding: 0, margin: '0.5rem', borderRadius: 0 }}
            />
          </Paper>
          <Button
            disabled={!labelCodeValue}
            variant="contained"
            color="primary"
            style={{
              display: 'inline-block',
              borderRadius: '0',
              width: '100vw',
              marginLeft: '0',
              marginRight: '0',
            }}
            onClick={next}
          >
            {nextStr}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default ScanDeviceBarcode;
