import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Container, Divider, Grid, Switch, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import BatteryStdIcon from '@material-ui/icons/BatteryStd';
import { NOT_AVAILABLE } from 'modules/osa/details/OSADeviceViewDetails';
import styles from './index.module.scss';
import { TVPowerChangeResponse, TVSensor, TVState } from '../model';
import { isProvisioned, isDisconnected, isLowBattery, powerOffTV, powerOnTV, tvPowerStatus, isTvOff, isPowerStateUnknown } from '../tvSensor.service';
import { barcodeToSNFormat, msAsDuration } from '../../../shared/services/utils';

const SHOW_NEXT_ON_OFF_TIME = false;

interface TVDeviceViewDetailsProps {
  sensorData: TVSensor;
  refresh: () => void;
}

const TVDeviceViewDetails = ({ sensorData, refresh }: TVDeviceViewDetailsProps): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const ACPowerStr = i18n({ id: 'devices.ACPower' });
  const batteryStatusStr = i18n({ id: 'devices.batteryStatus' });
  const checkDeviceStr = i18n({ id: 'devices.checkDevice' });
  const lastUpdateStr = i18n({ id: 'devices.lastUpdate' });
  const positionStr = i18n({ id: 'common.position' });
  const merchandiseStr = i18n({ id: 'merchandise.merchandise' });
  const pleaseWaitStr = i18n({ id: 'common.pleaseWait' });
  const deviceNumberStr = i18n({ id: 'devices.deviceNumber' });
  const tvOffStr = i18n({ id: 'devices.tvOff' });
  const tvOnStr = i18n({ id: 'devices.tvOn' });
  const deviceTypeStr = i18n({ id: 'devices.deviceType' });
  const installPendingStr = i18n({ id: 'devices.installPending' });
  const offlineStr = i18n({ id: 'common.offline' });
  const onlineStr = i18n({ id: 'common.online' });
  const hoursStr = i18n({ id: 'common.hours' });
  const daysStr = i18n({ id: 'common.days' });
  const powerStateUnknownStr = i18n({ id: 'devices.powerStateUnknown' });
  const nextOnOffStr = i18n({ id: 'devices.nextOnOff' });
  const brandStr = i18n({ id: 'common.brand' });
  const departmentStr = i18n({ id: 'common.department' });

  const [open, setOpen] = useState(false);

  const isOn = !isDisconnected(sensorData)
    ? tvPowerStatus(sensorData) === TVState.ON
    : false;

  const isOff = !isDisconnected(sensorData)
    ? isTvOff(sensorData)
    : false;

  const powerStatusStr = isOn ? tvOnStr : tvOffStr;

  // TODO: determine time of next power change once backend is complete
  let nextOnOffTime;
  if (sensorData.config && sensorData.config.nextScheduledCommand) {
    const nextCommand = sensorData.config.nextScheduledCommand;
    nextOnOffTime = `${nextCommand.hour}:${nextCommand.minute}`;
  }

  // determine sensor status
  let issueDurationTime = '';
  let sensorStatusStr;
  if (!isProvisioned(sensorData)) {
    sensorStatusStr = installPendingStr;
  } else if (isDisconnected(sensorData)) {
    const timeSinceOffline = sensorData.currentState
      ? (Date.now() - sensorData.currentState?.updateTimeMs) : 0;
    sensorStatusStr = `${offlineStr}:`;
    issueDurationTime = msAsDuration(timeSinceOffline, daysStr, hoursStr);
  } else {
    sensorStatusStr = onlineStr;
  }

  const lastReportTime = (isProvisioned(sensorData) && sensorData.currentState?.updateTimeISO)
    ? moment(sensorData.currentState?.updateTimeISO).format('lll')
    : 'No update found';

  // TODO add ac power data

  // determine battery status
  const batteryStatus = isLowBattery(sensorData)
    ? <BatteryStdIcon color="error" />
    : <BatteryStdIcon style={{ color: 'green' }} />;

  // display sensor configuration
  const deviceType = sensorData.classId === 'tv' ? 'TV Manager' : checkDeviceStr;
  const barcode = sensorData.labelCode;
  const serialNumber = barcodeToSNFormat(sensorData.labelCode);

  // handle power changes via UI switch
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    event.preventDefault();
    setOpen(true);
    let response: TVPowerChangeResponse;
    if (isOn) {
      response = await powerOffTV(serialNumber);
      if (response) {
        refresh();
      }
    } else {
      response = await powerOnTV(serialNumber);
      if (response) {
        refresh();
      }
    }
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Container style={{ marginTop: '2em' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          {isOff || isOn
            ? (
              <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                {powerStatusStr}
              </Typography>
            )
            : (
              <Typography variant="body2" style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                {powerStateUnknownStr}
              </Typography>
            )}
        </Grid>
        <Grid item xs={6}>
          <div hidden={isPowerStateUnknown(sensorData)}>
            <Switch
              hidden={isPowerStateUnknown(sensorData)}
              name={barcode}
              checked={isOn}
              onChange={handleChange}
              color="primary"
            />
          </div>
        </Grid>
        {SHOW_NEXT_ON_OFF_TIME
          ? (
            <>
              <Grid item xs={6}>
                <Typography noWrap style={{ textTransform: 'capitalize' }}>
                  {`${nextOnOffStr}:`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography noWrap style={{ textTransform: 'capitalize' }}>
                  {nextOnOffTime || 'N/A'}
                </Typography>
              </Grid>
            </>
          ) : null}
      </Grid>
      <Dialog open={open}>
        <>
          <DialogTitle id="loading-dialog">{pleaseWaitStr}</DialogTitle>
          <Paper elevation={0} className={styles.progressDialog}>
            <CircularProgress />
            <Button color="secondary" onClick={handleClose}>Close</Button>
          </Paper>
        </>
      </Dialog>
      <Divider />
      <Grid container spacing={2} justify="space-between" alignItems="flex-start">
        <Grid item xs={6}>
          <Typography noWrap style={{ textTransform: 'capitalize' }}>
            {sensorStatusStr}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography noWrap>
            {issueDurationTime}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="left" style={{ textTransform: 'capitalize' }}>
            {lastReportTime ? `${lastUpdateStr}:` : ''}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{lastReportTime}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {`${ACPowerStr}:`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            OK
            { /* FIXME */ }
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles['battery-icon']}>
            {!isDisconnected(sensorData)
              ? `${batteryStatusStr}:` : null}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles['battery-icon']}>
            {!isDisconnected(sensorData)
              ? batteryStatus : null}
          </Typography>
        </Grid>
        <Divider />
        <Grid item xs={6}>
          <Typography>{`${deviceTypeStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{deviceType}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${deviceNumberStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{sensorData.labelCode}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${merchandiseStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {sensorData.merchandise?.name}
            <br />
            {sensorData.merchandise?.code?.trim() || NOT_AVAILABLE}
            <br />
            {sensorData.merchandise?.deviceUPC?.trim() || NOT_AVAILABLE}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${brandStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {sensorData?.merchandise?.brand?.trim() || NOT_AVAILABLE}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${positionStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{sensorData.positionInStore?.name}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${departmentStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{sensorData.positionInStore?.department?.trim() || NOT_AVAILABLE}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TVDeviceViewDetails;
