import { getToken } from './token.service';
import { AxiosStatic, AxiosRequestConfig } from 'common-axios';

let axios: AxiosStatic;

const defaultUnauthorizedHandler = () => { };
// Use this value for baseURL: https://us-central1-invue-live.cloudfunctions.net/handleApiDeviceEventsStaging/api

const defaultConfig: AxiosRequestConfig = {
  timeout: 75000,
  baseURL: '/api',
};

function configureAxios(
  appAxios: AxiosStatic,
  unauthorizedHandler: () => void = defaultUnauthorizedHandler,
  config: AxiosRequestConfig = defaultConfig
) {
  axios = appAxios;
  const axiosConfig: AxiosRequestConfig = {
    ...defaultConfig,
    ...config
  };
  const instance = axios.create(axiosConfig);

  instance.interceptors.request.use((config) => {
    const authToken = getToken();
    let updatedConfig = { ...config };
    if (config && config.headers && !config.headers.Authorization && authToken) {
      updatedConfig.headers = {
        ...updatedConfig.headers,
        Authorization: 'Bearer ' + authToken
      }
    }
    return updatedConfig;
  }, (error) => {
    return Promise.reject(error);
  });

  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      return Promise.resolve(error.request);
    }
    if (error.response && error.response.status === 401) {
      unauthorizedHandler();
    }
    return Promise.reject(error);
  });

  return instance;
}

export {
  configureAxios
};
