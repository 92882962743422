import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LiveDashboard from 'modules/dashboard';
import OSAList from 'modules/osa/list';
import OSADeviceView from 'modules/osa/details';
import TVList from 'modules/tv-sensors/list';
import TVDeviceView from 'modules/tv-sensors/details';
import TVPowerSchedule from 'modules/tv-sensors/list/TVPowerSchedule';
import SecureDisplaysList from 'modules/secure-displays/list';
import Devices from 'modules/devices';
import AddDevice from '../devices/add-device';

const tenantId = sessionStorage.tenant_key;
const Routes = (): JSX.Element => (
  <Switch>
    <Redirect exact from="/" to="/dashboard" />
    <Route exact path="/dashboard" component={LiveDashboard} />
    <Route exact path="/devices" component={Devices} />
    <Route exact path="/devices/scan" component={AddDevice} />
    <Route exact path="/osa" component={OSAList} />
    <Route exact path="/osa/device/:deviceSerNum" component={OSADeviceView} />
    <Route exact path="/tvs" component={TVList} />
    <Route exact path="/tvs/device/:deviceSerNum" component={TVDeviceView} />
    <Route exact path="/tv/schedule" component={TVPowerSchedule} />
    {tenantId === 'development' ? <Route exact path="/secure-displays" component={SecureDisplaysList} />
      : <Redirect exact from="/secure-displays" to="/dashboard" />}
  </Switch>
);

export default Routes;
