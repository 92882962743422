import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ConfirmationDialogProps {
  title?: string;
  contentText?: string;
  handleClose?(): void;
  handleAgree(): void;
  open: boolean;
  cancelLabel?: string;
  confirmLabel: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
  { open,
    handleClose,
    title,
    contentText,
    handleAgree,
    cancelLabel,
    confirmLabel }: ConfirmationDialogProps,
) => (
  <Dialog
    open={open}
    onClose={handleClose}
  >
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>
      <DialogContentText>
        {contentText}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      { cancelLabel
        && (
        <Button
          id="cancel-button"
          onClick={handleClose}
          color="primary"
          variant="contained"
          autoFocus
        >
          {cancelLabel}
        </Button>
        )}
      <Button id="confirm-button" onClick={handleAgree} color="primary">
        {confirmLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
