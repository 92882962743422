import React, { ReactElement, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Container from '@material-ui/core/Container';
import ScanDeviceBarcode from './ScanDeviceBarcode';
import ConfirmDevice from './ConfirmDevice';
import SelectPositionInStore from './SelectPositionInStore';
import NewDevicePreview from './NewDevicePreview';
import AddDeviceStepper from './Stepper';
import ScanMerchandise from './ScanMerchandise';
import { Merchandise } from '../../merchandise/model';
import { PositionInStore } from '../../positionsInStore/model';
import { Location, DeviceType } from './model';
import { getDefaultOsaSensorSettings } from '../devices.service';
import { IMPERIAL, MM_INCH_VALUE, DEFAULT_POSITION_DEPTH_MM, OSA_THRESHOLD_ZERO } from './constants';

const AddDevice = (): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const location: Location = useLocation();

  const scanDeviceStr = i18n({ id: 'devices.scanDevice' });
  const scanMerchandiseStr = i18n({ id: 'devices.scanMerchandise' });
  const selectPositionStr = i18n({ id: 'devices.selectPosition' });
  const reviewDetailsStr = i18n({ id: 'devices.reviewDetails' });

  const addSteps = [
    scanDeviceStr,
    scanMerchandiseStr,
    selectPositionStr,
    reviewDetailsStr,
  ];

  const initialActiveStep: number = location.state ? location.state.step : 0;
  const initialLabelCode: string = location.state ? location.state.labelCode : '';
  const initialDeviceSN: string = location.state ? location.state.serialNumber : '';
  const initialDeviceType: DeviceType = location.state ? location.state.deviceType : {
    type: 'unknown',
    display: '',
  };
  const initialMerchandiseData: Merchandise = location.state ? location.state.merchandise : {
    code: '',
    name: '',
  };
  const initialPositionInStoreData: PositionInStore = location.state
    ? location.state.positionInStore : {
      name: '',
    };

  const initialPositionEmptyDepthData = location.state ? location.state.positionEmptyDepthMm : 0;
  const initialPositionLowWarningData = location.state && location.state.positionLowWarning
    ? location.state.positionLowWarning : OSA_THRESHOLD_ZERO;
  const [positionLowWarning, setPositionLowWarning] = useState(initialPositionLowWarningData);
  const [activeStep, setActiveStep] = useState(initialActiveStep);
  const [labelCode, setLabelCode] = useState(initialLabelCode);
  const [serialNumber, setSerialNumber] = useState(initialDeviceSN);
  const [deviceType, setDeviceType] = useState(initialDeviceType);
  const [merchandise, setMerchandise] = useState(initialMerchandiseData);
  const [merchandiseScanValue, setMerchandiseScanValue] = useState('');
  const [positionInStore, setPositionInStore] = useState(initialPositionInStoreData);
  const [positionEmptyDepth, setPositionEmptyDepth] = useState(initialPositionEmptyDepthData);

  useEffect(() => {
    getDefaultOsaSensorSettings().then((values) => {
      if (!initialPositionEmptyDepthData) {
        if (values.length > 0 && values[0].positionDepth !== undefined) {
          const { positionDepthUnits, positionDepth } = values[0];
          const positionDepthValue = positionDepthUnits === IMPERIAL
            ? Math.round(positionDepth * MM_INCH_VALUE) : positionDepth;
          setPositionEmptyDepth(positionDepthValue);
        } else {
          setPositionEmptyDepth(DEFAULT_POSITION_DEPTH_MM);
        }
      }
      if (!location.state) {
        if (values.length > 0 && values[0].positionLowWarning !== undefined) {
          setPositionLowWarning(values[0].positionLowWarning);
        }
      }
    });
  }, [initialPositionEmptyDepthData, location.state]);

  const handleNextStep = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReturnToDeviceScan = (): void => {
    setActiveStep(0);
  };

  const handleReturnToMerchandiseScan = (): void => {
    setActiveStep(1);
  };

  const handleReturnToPositionSelect = (): void => {
    setActiveStep(2);
  };

  const handleScanDeviceNextStep = (labelValue: string, serialNumberValue: string): void => {
    setLabelCode(labelValue);
    setSerialNumber(serialNumberValue);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const merchandiseNextStep = (): void => {
    setActiveStep(positionInStore.name ? 3 : 2);
  };

  const handleMerchCodeChange = (code: string): void => {
    setMerchandiseScanValue(code);
  };

  const findMerchandise = (code: string, name: string,
    deviceUPC?: string, brand?: string): void => {
    setMerchandise({ code, name, deviceUPC, brand });
  };

  const handlePositionChange = (name: string, department?: string): void => {
    setPositionInStore({ name, department });
  };

  const handleDepthChange = (newValue: number): void => {
    setPositionEmptyDepth(newValue);
  };

  const handlePositionLowWarningChange = (newValue: string): void => {
    setPositionLowWarning(newValue);
  };

  const handleExistingDevice = (
    serialNum: string,
    existingMerchCode: string,
    existingMerchName: string,
    existingLocName: string,
    existingPositionEmptyDepth: number,
    existingMerchDeviceUpc?: string,
    existingBrand?: string,
    existingDepartment?: string,
  ): void => {
    setSerialNumber(serialNum);
    setPositionInStore({ name: existingLocName, department: existingDepartment });
    setPositionEmptyDepth(existingPositionEmptyDepth);
    findMerchandise(existingMerchCode, existingMerchName, existingMerchDeviceUpc, existingBrand);
    if ((existingMerchCode || existingMerchDeviceUpc)
      && existingLocName) {
      setActiveStep(3);
    } else {
      setActiveStep(1);
    }
  };

  const handleDeviceSNChange = async (newValue: string): Promise<void> => {
    setLabelCode(newValue);
    switch (newValue.replace(/-/gi, '').substr(2, 2)) {
      case '00':
        setDeviceType({
          type: 'test',
          display: 'Test Device',
        });
        break;
      case '01':
        setDeviceType({
          type: 'osa',
          display: 'On-Shelf Availability Sensor',
        });
        break;
      case '02':
        setDeviceType({
          type: 'tv',
          display: 'TV Manager',
        });
        break;
      case '03':
        setDeviceType({
          type: 'alarm',
          display: 'Alarm Node',
        });
        break;
      case '04':
        setDeviceType({
          type: 'onePod',
          display: 'OnePod Live',
        });
        break;
      case '05':
        setDeviceType({
          type: 'vertical',
          display: 'Vertical Tetherless Live',
        });
        break;
      default:
        setDeviceType({
          type: 'unknown',
          display: 'Unknown Device',
        });
        break;
    }
  };

  function stepContent(): ReactElement {
    switch (activeStep) {
      case 0:
        return (
          <div>
            <ScanDeviceBarcode
              onChange={handleDeviceSNChange}
              labelCodeValue={labelCode}
              nextStep={handleScanDeviceNextStep}
              onExistingDevice={handleExistingDevice}
            />
          </div>
        );
      case 10:
        return (
          <ConfirmDevice
            labelCodeValue={labelCode}
            nextStep={handleNextStep}
            deviceTypeValue={deviceType}
            returnToPrevScreen={handleReturnToDeviceScan}
          />
        );
      case 1:
        return (
          <ScanMerchandise
            nextStep={merchandiseNextStep}
            returnToPrevScreen={handleBack}
            onChange={handleMerchCodeChange}
            merchandiseScanValue={merchandiseScanValue}
            onFindMerchandise={findMerchandise}
          />
        );
      case 2:
        return (
          <SelectPositionInStore
            onSelectPosition={handlePositionChange}
            positionInStoreValue={positionInStore.name}
            nextStep={handleNextStep}
            previousStep={handleBack}
          />
        );
      case 3:
        return (
          <NewDevicePreview
            labelCodeValue={labelCode}
            serialNum={serialNumber}
            deviceTypeValues={deviceType}
            merchCodeValue={merchandise.code}
            merchNameValue={merchandise.name}
            positionInStoreValue={positionInStore.name}
            positionLowWarningValue={positionLowWarning}
            positionEmptyDepthValue={positionEmptyDepth}
            scanMerchandise={handleReturnToMerchandiseScan}
            selectPositionInStore={handleReturnToPositionSelect}
            onChange={handleDepthChange}
            onPositionLowWarningChange={handlePositionLowWarningChange}
            merchDeviceUpcValue={merchandise.deviceUPC}
            brandValue={merchandise.brand}
            departmentValue={positionInStore.department}
          />
        );
      default:
        return <p>Error: You have discovered a nonexistent step!</p>;
    }
  }

  return (
    <Container disableGutters>
      <AddDeviceStepper
        currentStepNumber={activeStep}
        currentStepName={addSteps[activeStep]}
        nextStepName={addSteps[activeStep + 1]}
        numberOfSteps={addSteps.length}
      />
      {stepContent()}
    </Container>
  );
};

export default AddDevice;
