export interface DescriptionText {
  text: string;
  bold?: boolean;
}

export enum ListItemErrorStatus {
  None = 0,
  warning = 1,
  Danger = 2,
}

export interface ItemStatus {
  text?: string;
  desc?: string;
  errorStatus?: ListItemErrorStatus;
}
