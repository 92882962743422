import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';
import { StoreModel } from './model';

interface Props {
  stores: StoreModel[];
  value: string;
  onChange(storeId: string): void;
  open: boolean;
  onDone(): void;
  onClose(): void;
}

export const StoreSelect = (props: Props): JSX.Element => {
  const { stores, value, onChange, open, onDone, onClose } = props;

  const { formatMessage: i18n } = useIntl();
  const storeSelectTxt = i18n({ id: 'store.storeSelect' });
  const doneBtnTxt = i18n({ id: 'store.doneStatus' });

  const handleStoreChange = (event: React.ChangeEvent<{ value: string | unknown }>): void =>
    onChange(event.target.value as string);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth disableBackdropClick>
      <DialogContent>
        <DialogContentText className={styles.dialogTitle}>
          {storeSelectTxt}
        </DialogContentText>
        <FormControl variant="outlined" fullWidth className={styles.formControl}>
          <Select
            value={value}
            onChange={handleStoreChange}
            fullWidth
          >
            {
              stores.length > 0 && stores.map((store: StoreModel) => (
                <MenuItem key={store.DOCUMENT_KEY} value={store.DOCUMENT_KEY}>
                  {store.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          fullWidth
          onClick={onDone}
          color="primary"
          disabled={value === ''}
          className={styles.doneBtn}
        >
          {doneBtnTxt}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
