import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InVueLogo from 'shared/assets/inVue_Logo.png';
import { setStore } from 'common/services/token.service';
import { intlCompareStrings } from 'shared/services/utils';
import styles from './index.module.scss';
import { login } from './login.service';
import { getAllStores } from './storeSelect/storeSelect.service';
import { StoreSelect } from './storeSelect';
import { StoreModel } from './storeSelect/model';

const initialStores: StoreModel[] = [];

function Login({ history }: RouteComponentProps): JSX.Element {
  const searchParams = new URLSearchParams(history.location.search);
  const redirectURL = searchParams ? searchParams.get('redirect') : '';

  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState('');
  const [stores, setStores] = useState(initialStores);

  const { formatMessage: i18n } = useIntl();
  const loginBttnTxt = i18n({ id: 'login.loginBttnTxt' });
  const loginLabel = i18n({ id: 'login.username' });
  const passLabel = i18n({ id: 'login.password' });
  const usrNameEmpty = i18n({ id: 'login.usrNameEmpty' });
  const passEmpty = i18n({ id: 'login.passEmpty' });
  const failedLoginStr = i18n({ id: 'login.failedLogin' });

  const loginValidationSchema = Yup.object().shape({
    userName: Yup.string().trim()
      .required(usrNameEmpty),
    password: Yup.string()
      .required(passEmpty),
  });

  const {
    handleSubmit, handleChange, handleBlur, values, errors, touched, isValid,
  } = useFormik({
    initialValues: {
      userName: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    async onSubmit() {
      try {
        const { userName, password } = values;
        await login(userName, password);
        const allStores = await getAllStores();
        setStores(allStores.sort((a, b) => intlCompareStrings(a.name, b.name)));
        if (allStores && allStores.length > 1) {
          setOpen(true);
        } else {
          if ((allStores && allStores.length === 1) && allStores[0].DOCUMENT_KEY) {
            setStore(allStores[0].DOCUMENT_KEY);
          }
          if (redirectURL) {
            history.push(redirectURL);
          }
          history.push('/');
        }
      } catch (err) {
        setErrorMessage(failedLoginStr);
      }
    },
  });

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleDone = (): void => {
    setOpen(false);
    setStore(selectedStoreId);
    if (redirectURL) {
      history.push(redirectURL);
    }
    history.push('/');
  };

  const handleStoreChange = (storeId: string): void => {
    setSelectedStoreId(storeId);
  };

  return (
    <>
      <div className={styles.container}>
        <Container component="main" maxWidth="xs">
          <div className={styles.invueLogo}>
            <img className={styles.logo} src={InVueLogo} alt="InVue" />
            <br />
          </div>
          {errorMessage ? (
            <Typography color="error" style={{ textAlign: 'center' }}>{errorMessage}</Typography>
          ) : null}
          <form onSubmit={handleSubmit}>
            <TextField
              label={loginLabel}
              id="userName"
              name="userName"
              type="text"
              fullWidth
              value={values.userName}
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                ((touched.userName) && errors.userName)
                  ? (touched.userName) && errors.userName
                  : ''
              }
              error={
                (touched.userName && Boolean(errors.userName)
                  ? touched.userName && Boolean(errors.userName)
                  : false)
              }
              margin="normal"
              required
              autoComplete="userName"
            />
            <TextField
              id="password"
              label={passLabel}
              name="password"
              type="password"
              fullWidth
              value={values.password}
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                ((touched.password) && errors.password)
                  ? (touched.password) && errors.password
                  : ''
              }
              error={
                (touched.password && Boolean(errors.password))
                  ? touched.password && Boolean(errors.password)
                  : false
              }
              margin="normal"
              required
              autoComplete="current-password"
            />

            <Button
              id="loginBtn"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={styles.submit}
              disabled={!isValid}
            >
              {loginBttnTxt}
            </Button>
          </form>
        </Container>
      </div>
      <StoreSelect
        stores={stores}
        value={selectedStoreId}
        onChange={handleStoreChange}
        open={open}
        onDone={handleDone}
        onClose={handleClose}
      />
    </>
  );
}

export default Login;
