import { IntlShape } from 'react-intl';
import { SelectItem } from './SelectField';

export const METRIC = 'metric';
export const IMPERIAL = 'imperial';
export const MM_INCH_VALUE = 25.4;
export const OSA_THRESHOLD_ZERO = '0';
export const OSA_THRESHOLD_TEN = '10';
export const OSA_THRESHOLD_TWENTY = '20';
export const OSA_THRESHOLD_THIRTY = '30';
export const OSA_THRESHOLD_FORTY = '40';
export const OSA_THRESHOLD_FIFTY = '50';
export const DEFAULT_POSITION_DEPTH_MM = 300;

export const getPositionDepthUnits = (intl: IntlShape): SelectItem[] => {
  const { formatMessage: i18n } = intl;
  return [{ id: METRIC, name: i18n({ id: 'common.mm' }) },
    { id: IMPERIAL, name: i18n({ id: 'common.inch' }) }];
};

export const getThresholdForSensors = (): SelectItem[] => [
  { id: OSA_THRESHOLD_ZERO, name: '0%' },
  { id: OSA_THRESHOLD_TEN, name: '10%' },
  { id: OSA_THRESHOLD_TWENTY, name: '20%' },
  { id: OSA_THRESHOLD_THIRTY, name: '30%' },
  { id: OSA_THRESHOLD_FORTY, name: '40%' },
  { id: OSA_THRESHOLD_FIFTY, name: '50%' },
];

/**
 * Convertes INPUT MM value to value of provided unit
 * @param value input value in number
 * @param unit new unit string to which value should be converted
 */
const convertMMValues = (value: number, unit: string): number => {
  let convertedValue = value;
  switch (unit) {
    case IMPERIAL:
      convertedValue = value / MM_INCH_VALUE;
      break;
    default:
      break;
  }
  return convertedValue;
};

/**
 * Convertes INPUT INCH value to value of provided unit
 * @param value input value in number
 * @param unit new unit string to which value should be converted
 */
const convertInchValues = (value: number, unit: string): number => {
  let convertedValue = value;
  switch (unit) {
    case METRIC:
      convertedValue = value * MM_INCH_VALUE;
      break;
    default:
      break;
  }
  return convertedValue;
};

/**
 * Convertes input value to new value from one unit to another unit
 * @param value input value in number
 * @param currentUnit current unit string of which input value is passed
 * @param newUnit new unit string to which value should be converted
 */
export const convertUnitValues = (value: number, currentUnit: string, newUnit: string): number => {
  let convertedValue = value;
  switch (currentUnit) {
    case IMPERIAL:
      convertedValue = convertInchValues(value, newUnit);
      break;
    case METRIC:
      convertedValue = convertMMValues(value, newUnit);
      break;
    default:
      break;
  }
  return convertedValue;
};
