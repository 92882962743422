import httpClient from 'shared/services/http-client';
import { useIntl } from 'react-intl';
import { getStore, getTenant } from 'common/services/token.service';
import { TVManufacturer, TVPowerChangeResponse, TVSensor, TVState, TVwithCount, BusinessHoursModel } from './model';
import { endpoints } from '../../shared/services/endpoints.constants';

export const tvPowerStatus = (item: TVSensor): TVState => (item.config
  ? item.config.tvState : TVState.UNKNOWN);

export const isTvOff = (item: TVSensor): boolean => item.config?.tvState === TVState.OFF;

export const isDisconnected = (item: TVSensor): boolean => {
  const ONE_HOUR = 3600000;
  const minimumTimeSinceLastUpdate = /* 259200000 || */ (ONE_HOUR * 72);
  // TODO user set time || 3 days
  const offlineTime = Date.now() - minimumTimeSinceLastUpdate;
  if (item.currentState) {
    return item.currentState.updateTimeMs <= offlineTime;
  }
  return false;
};

// export const isRemovedFromPower = (item: TVSensor): boolean =>  FIXME Add when db updated

// export const isRemovedFromHDMI = (item: TVSensor): boolean => FIXME Add when db updated

export const isProvisioned = (item: TVSensor): boolean => {
  if (!item.currentState) {
    return false;
  }
  return true;
};

export const isPowerStateUnknown = (item: TVSensor): boolean => ((!item.config)
  ? true : item.config?.tvState === TVState.UNKNOWN);

export const isLowBattery = (item: TVSensor): boolean | undefined => {
  if (item.currentState) {
    return item.currentState.batteryValue <= 3200; // 3.2 volts per tv sensor FW documentation
  }
  return undefined;
};

export const isIssue = (item: TVSensor): boolean | undefined =>
  isDisconnected(item)
  || isPowerStateUnknown(item)
  || !isProvisioned(item)
  || isLowBattery(item);

export const fetchTVData = async (): Promise<TVwithCount> => {
  const url = endpoints.TV_SENSORS_DATA
    .replace('{tenantId}', sessionStorage.tenant_key || 'unknownTenant')
    .replace('{storeId}', sessionStorage.store_key || 'unknownStore');
  const response = await httpClient.get(url);
  const items = response.data;
  const count = {
    totalCount: items.length,
    offlineCount: 0,
    poweredOffCount: 0,
    poweredOnCount: 0,
    TVsWithIssuesCount: 0,
  };
  const filteredItems = items.filter((item: TVSensor) => !(item.isDeleted));
  filteredItems.forEach((item: TVSensor) => {
    if (
      (isDisconnected(item))
      || (isLowBattery(item))
      || (!isProvisioned(item))
      || (tvPowerStatus(item) === TVState.UNKNOWN)
      // || isRemovedFromPower(item)
      // || isRemovedFromHDMI(item)
      // TODO || any other flagged issue
    ) {
      count.TVsWithIssuesCount += 1;
    }
    if (isDisconnected(item)) {
      count.offlineCount += 1;
    }
    if (!isDisconnected(item) && tvPowerStatus(item) === TVState.OFF) {
      count.poweredOffCount += 1;
    }
    if (tvPowerStatus(item) === TVState.ON) {
      count.poweredOnCount += 1;
    }
    // TODO add other issues
  });
  return {
    count,
    items: filteredItems.map((item: TVSensor) => ({
      ...item,
    })),
  };
};

export const fetchTVDataSortedByPower = async (): Promise<TVSensor[]> => {
  const response = await httpClient.get(endpoints.TV_SENSORS_DATA_SORT_BY_POWER);
  const items = response.data;
  return items;
};

export const getTimeSinceIssue = (timeInMs: number | undefined): string => {
  if (timeInMs) {
    const timeSinceOff = Date.now() - timeInMs;
    const sec = timeSinceOff / 1000;
    if (sec >= (3600 * 24 * 14)) {
      return `${Math.round(sec / (3600 * 24 * 7))} weeks`;
    } if (sec >= (3600 * 48)) {
      return `${Math.round(sec / (3600 * 24))} days`;
    } if (sec >= (3600 * 2)) {
      return `${Math.round(sec / 3600)} hours`;
    } if (sec >= 120) {
      return `${Math.round(sec / 60)} minutes`;
    } if (sec >= 10) {
      return `${Math.round(sec)} seconds`;
    }
    return `< 10 seconds`;
  }
  return '';
};

export const useIntlOffline = (): string => {
  const { formatMessage: i18n } = useIntl();
  return i18n({ id: 'common.offline' });
};

export const powerOnTV = async (serialNumber: string): Promise<TVPowerChangeResponse> => {
  const url = endpoints.SINGLE_TV_DEVICE_CONFIG
    .replace('{serNum}', serialNumber);
  const data = { isTvPoweredOn: true };
  const tenant = getTenant();
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenant,
    },
  };
  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const powerOffTV = async (serialNumber: string): Promise<TVPowerChangeResponse> => {
  const url = endpoints.SINGLE_TV_DEVICE_CONFIG
    .replace('{serNum}', serialNumber);
  const data = { isTvPoweredOn: false };
  const tenant = getTenant();
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenant,
    },
  };
  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const powerOnAllTVs = async (): Promise<TVPowerChangeResponse> => {
  const storeId = getStore() || '';
  const url = endpoints.ALL_TV_DEVICES_CONFIG
    .replace('{storeId}', storeId);
  const data = { isTvPoweredOn: true };
  const tenant = getTenant();
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenant,
    },
  };
  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const powerOffAllTVs = async (): Promise<TVPowerChangeResponse> => {
  const storeId = getStore() || '';
  const url = endpoints.ALL_TV_DEVICES_CONFIG
    .replace('{storeId}', storeId);
  const data = { isTvPoweredOn: false };
  const tenant = getTenant();
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenant,
    },
  };
  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const fetchTVDevice = async (serialNumber: string): Promise<TVSensor> => {
  const url = endpoints.SINGLE_TV_SENSOR_DATA
    .replace('{serNum}', serialNumber);
  const response = await httpClient.get(url);
  return response.data;
};

export const matchesFilterCriteria = (item: TVSensor, searchData: string): boolean => {
  if (item.positionInStore?.name) {
    return item.merchandise.name.toUpperCase().includes(searchData.toUpperCase())
      || item.positionInStore.name.toUpperCase().includes(searchData.toUpperCase());
  }
  return item.merchandise.name.toUpperCase().includes(searchData.toUpperCase());
};

export const getTVManufacturerName = async (manufacturerCode: string): Promise<TVManufacturer> => {
  const url = endpoints.TV_MANUFACTURER_BY_CODE
    .replace('{manufacturerCode}', manufacturerCode);
  const response = await httpClient.get(url);
  return response.data;
};

export const refreshEdidData = async (serialNumber: string): Promise<void> => {
  const url = endpoints.SINGLE_TV_DEVICE_CONFIG
    .replace('{serNum}', serialNumber);
  const data = { refreshEdidData: true };
  await httpClient.post(url, data);
};

export const deleteTVSensor = async (serialNumber: string): Promise<string> => {
  const url = endpoints.SINGLE_TV_SENSOR_DATA
    .replace('{serNum}', serialNumber);
  const response = await httpClient.put(url, { isDeleted: true });
  return response.data;
};

export const fetchBusinessHours = async (): Promise<BusinessHoursModel[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  const url = endpoints.BUSINESS_HOURS
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url);
  return response.data;
};
