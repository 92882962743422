import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Tabs, Tab, Paper, Grid, Typography, IconButton, Box } from '@material-ui/core';
import NoDeviceSetup from 'shared/ui/noDeviceSetup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchOSAData } from '../osa.service';
import { OSACount, OSASensor, OSAWithCount } from '../model';
import AllOSA from './views/All';
import OutOfStockOSA from './views/OOS';
import IssuesOSA from './views/Issues';
import styles from './index.module.scss';

const initialList: OSASensor[] = [];
const defaultItemsCount: OSACount = {
  totalCount: 0,
  emptyCount: 0,
  offlineCount: 0,
  sensorsWithIssuesCount: 0,
};

const OSAList = (): JSX.Element => {
  const initialPositionLowWarning = -1;
  const [warningValue, setWarningValue] = useState(initialPositionLowWarning);
  const [itemsCount, setItemsCount] = useState(defaultItemsCount);
  const [OSAItems, setOSAItems] = useState(initialList);
  const [tabIndex, setTabIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const history = useHistory();

  const refreshInterval = Number(sessionStorage.refresh_key);

  const displayIndex = sessionStorage.getItem('osaTabIndex');

  const refreshSensorData = (): void => {
    setRefresh((prevState) => !prevState);
  };

  const { formatMessage: i18n } = useIntl();
  const emptyLowStockStr = i18n({ id: 'dashboard.emptyLowStock' });
  const stockStr = i18n({ id: 'devices.stock' });
  const issuesStr = i18n({ id: 'common.issues' });
  const allStr = i18n({ id: 'common.all' });

  useEffect(() => {
    fetchOSAData().then(({ count, items, positionLowWarning }: OSAWithCount) => {
      if (count && items) {
        setItemsCount(count);
        setWarningValue(positionLowWarning);
        setOSAItems(items.sort((a, b) => a.merchandise.name.localeCompare(b.merchandise.name)));
        if (displayIndex != null) {
          setTabIndex(Number(displayIndex));
        } else if (count.emptyCount > 0) {
          setTabIndex(0);
        } else if (count.sensorsWithIssuesCount > 0) {
          setTabIndex(1);
        } else {
          setTabIndex(2);
        }
      }
      setIsLoading(false);
      // eslint-disable-next-line no-console
    }).catch((e) => console.log(e));
    const timeoutInterval = setTimeout(refreshSensorData, refreshInterval);
    return (): void => clearTimeout(timeoutInterval);
  }, [refresh, displayIndex, refreshInterval]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    sessionStorage.setItem('osaTabIndex', String(newValue));
    setTabIndex(newValue);
  };

  const back = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    history.goBack();
  };

  const renderOSAView = (index: number, list: OSASensor[], warningVal: number): JSX.Element => {
    switch (index) {
      case 0:
        return <OutOfStockOSA list={list} positionLowWarning={warningVal} />;
      case 1:
        return <IssuesOSA list={list} positionLowWarning={warningVal} />;
      default:
        return <AllOSA list={list} positionLowWarning={warningVal} />;
    }
  };

  const OSAContent = OSAItems.length
    ? (
      <>
        <Paper style={{ width: '85vw', margin: '1em', padding: '1em' }}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item>
              <Typography align="center">
                <span style={{ fontWeight: 'bold' }}>
                  {`${itemsCount.emptyCount}/${itemsCount.totalCount}`}
                </span>
                <br />
                {`${emptyLowStockStr}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center">
                <span style={{ fontWeight: 'bold' }}>{itemsCount.sensorsWithIssuesCount}</span>
                <br />
                {issuesStr}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          variant="fullWidth"
          onChange={handleChange}
          aria-label="availability sensor Tabs"
          className={styles['tabs-container']}
        >
          <Tab data-testid="osa-empty-tab" label={`${stockStr} (${itemsCount.emptyCount})`} />
          <Tab data-testid="osa-issues-tab" label={`${issuesStr} (${itemsCount.sensorsWithIssuesCount})`} />
          <Tab data-testid="osa-all-tab" label={`${allStr}`} />
        </Tabs>
        {renderOSAView(tabIndex, OSAItems, warningValue)}
      </>
    ) : (
      <>
        <NoDeviceSetup />
      </>
    );

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: '95%' }}
      >
        <IconButton onClick={back}>
          <ArrowBackIcon />
        </IconButton>
        <Typography style={{ fontWeight: 'bold', marginRight: '48px' }}>OSA Sensors</Typography>
        <div />
      </Box>
      {isLoading
        ? (
          <div style={{ marginTop: '50vh' }}>
            <CircularProgress />
          </div>
        )
        : OSAContent}
    </>
  );
};

export default OSAList;
