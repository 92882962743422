import React from 'react';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import ListItem from 'shared/ui/device-list/list-item';
import { DescriptionText, ItemStatus } from 'shared/ui/device-list/list-item/model';
import { Link } from 'react-router-dom';
import { TVSensor } from '../../model';
import styles from './list-view.module.scss';
import { barcodeToSNFormat } from '../../../../shared/services/utils';

export interface TVListViewItemData {
  description?: DescriptionText[];
  TVData: TVSensor;
  status: ItemStatus;
  className?: string;
  toggle?: HTMLElement;
}

export interface TVListViewProps {
  list: TVListViewItemData[];
  headers?: string[];
}

const TVListView: React.FC<TVListViewProps> = ({
  list,
}: TVListViewProps): JSX.Element => {
  const getDescriptionByTVData = (item: TVSensor): DescriptionText[] =>
    [{ text: item.merchandise?.name || '', bold: true }, { text: item.positionInStore?.name || '' }];

  const pathSN = (item: TVSensor): string => `/tvs/device/${barcodeToSNFormat(item.labelCode || '0000')}`;

  const renderListItem = ({ description, status, TVData }: TVListViewItemData): JSX.Element => (
    <Link
      to={{
        pathname: pathSN(TVData),
      }}
      key={TVData.labelCode}
      style={{ color: 'black', textDecoration: 'none' }}
    >
      <ListItem
        description={description || getDescriptionByTVData(TVData)}
        status={status}
      />
      <Divider />
    </Link>
  );

  return (
    <Container className={styles.container}>
      {list.map(renderListItem)}
    </Container>
  );
};

export default TVListView;
