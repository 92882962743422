import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TvIcon from '@material-ui/icons/Tv';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CropFreeIcon from '@material-ui/icons/CropFree';
import LockIcon from '@material-ui/icons/Lock';
import Logout from '../logout';

const ListItems = (): JSX.Element => {
  const tenantId = sessionStorage.tenant_key;
  return (
    <div>
      <ListItem
        component="a"
        button
        href="/"
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Action Board" />
      </ListItem>
      <Divider />
      <ListItem
        component="a"
        button
        href="/devices/scan"
      >
        <ListItemIcon>
          <CropFreeIcon />
        </ListItemIcon>
        <ListItemText primary="Scan Device" />
      </ListItem>
      <Divider />
      <ListItem
        component="a"
        button
        href="/osa"
      >
        <ListItemIcon>
          <AssignmentTurnedInIcon />
        </ListItemIcon>
        <ListItemText primary="OSA" />
      </ListItem>
      <ListItem
        component="a"
        button
        href="/tvs"
      >
        <ListItemIcon>
          <TvIcon />
        </ListItemIcon>
        <ListItemText primary="TVs" />
      </ListItem>
      <Divider />
      {tenantId === 'development' ? (
        <>
          <ListItem
            component="a"
            button
            href="/secure-displays"
          >
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary="Secure Displays" />
          </ListItem>
          <Divider />
        </>
      ) : null}
      <Logout />
    </div>
  );
};

export default ListItems;
