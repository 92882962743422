import React from 'react';
import { Container, Typography, Divider, Grid } from '@material-ui/core';
import styles from './index.module.scss';

const OSADeviceViewHistory = (): JSX.Element => (
  <Container style={{ marginTop: '1em' }}>
    <Grid container spacing={3}>
      <Divider />
      <Grid item xs>
        <Typography className={styles['data-title']}>Event Description placeholder</Typography>
      </Grid>
      <Grid item xs>
        <Typography>Time stamp placeholder</Typography>
      </Grid>
      <Grid item xs>
        <Typography>Action placeholder</Typography>
      </Grid>
      <Divider />
    </Grid>
  </Container>
);

export default OSADeviceViewHistory;
