import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';
import { getDevices } from './devices.service';
import { Device } from './model';
import DeviceList from './deviceList';

const initialDevices: Device[] = [];

const Devices = (): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const noDevicesSetupStr = i18n({ id: 'devices.noDevicesSetup' });

  const [devices, setDevices] = useState(initialDevices);
  useEffect(() => {
    getDevices().then((values: Device[]) => {
      setDevices(values);
    });
  }, []);

  return (
    <>
      {devices.length
        ? <DeviceList devices={devices} />
        : (
          <div className={styles['text-message-wrapper']}>
            <pre style={{ fontFamily: 'inherit' }}>
              {noDevicesSetupStr}
            </pre>
          </div>
        )}
    </>
  );
};

export default Devices;
