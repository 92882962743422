import httpClient from 'shared/services/http-client';
import { endpoints } from '../../shared/services/endpoints.constants';
import { PositionInStore } from './model';

const getPositionsInStore = async (): Promise<PositionInStore[]> => {
  const response = await httpClient.get(endpoints.POSITIONS_IN_STORE);
  return response.data;
};

const searchPositionsByName = async (positionName: string): Promise<PositionInStore[]> => {
  const position = positionName;
  const url = endpoints.POSITIONS_IN_STORE_SEARCH_BY_NAME
    .replace(/{positionName}/g, position);
  const response = await httpClient.get(url);
  return response.data;
};

export {
  getPositionsInStore, searchPositionsByName,
};
