import React from 'react';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import InVueLogo from 'shared/assets/inVue-logo.png';
import ListItems from './ListItems';
import SearchField from '../../shared/ui/search-field';
import Routes from './Routes';
import './index.scss';

const Layout = (): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  const openPage = window.location.pathname;

  const searchableViews = [
    '/osa',
    '/tvs',
  ];

  return (
    <Container className="container">
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar className="tool-bar">
          <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start">
            <MenuIcon />
          </IconButton>
          <div style={{ flexGrow: 1, justifyContent: 'left' }}>
            <img src={InVueLogo} alt="logo" className="app-logo" />
          </div>
          {searchableViews.includes(openPage) ? (
            <SearchField />
          ) : null}
          <IconButton disabled color="inherit" edge="end">
            <NotificationsNoneIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        PaperProps={{ className: 'app-drawer' }}
      >
        <div>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List component="nav">
          <ListItems />
        </List>
        <Divider />
      </Drawer>
      <main className="main">
        <Routes />
      </main>
    </Container>
  );
};

export default Layout;
