import httpClient from 'shared/services/http-client';
import { getTenant, getStore } from 'common/services/token.service';
import {
  isOSAOutOfStock, isDisconnected, isOSALowStock,
  isLowStockEnabled, isIssue,
} from 'modules/osa/osa-util';
import { OSAWithCount, OSASensor } from './model';
import { endpoints } from '../../shared/services/endpoints.constants';

export const fetchOSAData = async (): Promise<OSAWithCount> => {
  const ONE_HOUR = 3600000;
  const offlineTime = Date.now() - ONE_HOUR;
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  const sensorSettingUrl = endpoints.OSA_SENSOR_SETTING
    .replace('{storeId}', sessionStorage.store_key);
  const sensorSettingresponse = await httpClient.get(sensorSettingUrl);
  const sensorSetting = sensorSettingresponse.data;
  let positionLowWarning = -1;
  if (sensorSetting.length > 0 && sensorSetting[0].positionLowWarning !== undefined) {
    positionLowWarning = sensorSetting[0].positionLowWarning;
  }
  const url = endpoints.OSA_SENSORS_DATA
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url);
  const items = response.data;
  const count = {
    totalCount: items.length,
    offlineCount: 0,
    emptyCount: 0,
    sensorsWithIssuesCount: 0,
  };
  const filteredItems = items.filter((item: OSASensor) => !(item.isDeleted));
  filteredItems.forEach((item: OSASensor) => {
    if (isIssue(item)) {
      count.sensorsWithIssuesCount += 1;
    }
    if (item.currentState) {
      if (item.currentState.updateTimeMs <= offlineTime) {
        count.offlineCount += 1;
      }
      if (!isDisconnected(item) && (isOSAOutOfStock(item)
        || (isOSALowStock(item) && isLowStockEnabled(item) && positionLowWarning > 0))) {
        count.emptyCount += 1;
      }
      // TODO add other issues
    }
  });
  return {
    count,
    positionLowWarning,
    items: filteredItems.map((item: OSASensor) => ({
      ...item,
    })),
  };
};

export const fetchOSADevice = async (serialNumber: string): Promise<OSASensor> => {
  const url = endpoints.SINGLE_OSA_SENSOR_DATA
    .replace('{serNum}', serialNumber);
  const response = await httpClient.get(url);
  const item = response.data;
  return item;
};

export const deleteOSASensor = async (serialNumber: string): Promise<string> => {
  const url = endpoints.SINGLE_OSA_SENSOR_DATA
    .replace('{serNum}', serialNumber);
  const response = await httpClient.put(url, { isDeleted: true });
  return response.data;
};
