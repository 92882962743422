import { Device } from '../../modules/devices/model';

export const dateToString = (date: Date): string => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  return Intl.DateTimeFormat(undefined, options).format(date);
};

export const barcodeToSNFormat = (labelCode: string): string =>
  `0E00${labelCode.substr(2, 2)}${labelCode.substr(0, 2)}00${labelCode.substr(4)}`.toUpperCase();

export const msAsDuration = (milliseconds: number, daysStr: string, hoursStr: string): string => {
  const MINUTES = Math.floor(milliseconds / 60000);
  if (milliseconds < 60000) { // is less than a minute
    return `00:01 ${hoursStr}`;
  }
  if (milliseconds < 3600000) { // if less than an hour
    return `00:${MINUTES.toLocaleString(undefined, { minimumIntegerDigits: 2 })} ${hoursStr}`;
  }
  if (milliseconds < 86400000) { // if less than a day
    return `${Math.floor(MINUTES / 60)
      .toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${(MINUTES % 60)
      .toLocaleString(undefined, { minimumIntegerDigits: 2 })} ${hoursStr}`;
  }
  if (milliseconds >= 86400000) { // if a day or more
    return `${Math.floor(MINUTES / 60 / 24)} ${daysStr} ${Math.floor((MINUTES / 60) % 24)
      .toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${(MINUTES % 60)
      .toLocaleString(undefined, { minimumIntegerDigits: 2 })} ${hoursStr}`;
  }
  return '';
};

export const intlCompareStrings = (item1: string, item2: string): number => {
  const COLLATOR = Intl.Collator(undefined, { numeric: true, usage: 'sort' });
  return COLLATOR.compare(item1, item2);
};

export const matchesFilterCriteria = (item: Device, searchData: string): boolean => {
  if (item.positionInStore && item.positionInStore.name) {
    return item.merchandise?.name.toUpperCase().includes(searchData.trim().toUpperCase())
      || item.positionInStore.name.toUpperCase().includes(searchData.trim().toUpperCase());
  }
  return item.merchandise?.name.toUpperCase().includes(searchData.trim().toUpperCase())
      || false;
};

export const getMillisecondsToStringFormatValue = (milliseconds: number): string => {
  let seconds = milliseconds / 1000;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);

  const hourStr = hours.toString().length === 2 ? hours : `0${hours}`;
  const minutesStr = minutes.toString().length === 2 ? minutes : `0${minutes}`;
  return `${hourStr}:${minutesStr}`;
};
