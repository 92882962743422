import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { GridColumn } from 'common/models/material-ui';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import { defaultColumns } from '../../../../../shared/ui/device-list/list-constants';
import { DescriptionText } from '../../../../../shared/ui/device-list/list-item/model';
import { barcodeToSNFormat } from '../../../../../shared/services/utils';

interface PowerListItemProps {
  barcode: string;
  description: DescriptionText[];
  toggle: boolean;
  columns?: GridColumn[];
  onChange: (name: string, checked: boolean) => void;
}

const useStyle = makeStyles({
  textBold: {
    fontWeight: 'bold',
    wordWrap: 'break-word',
  },
});

const PowerListItem = (props: PowerListItemProps): JSX.Element => {
  const styles = useStyle(props);
  const {
    barcode, toggle, description, columns = defaultColumns,
  } = props;

  const pathSN = (code: string): string => `/tvs/device/${barcodeToSNFormat(code || '0000')}`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    props.onChange(event.target.name, event.target.checked);
  };

  return (
    <Grid container spacing={1} justify="space-between">
      <Grid item xs={columns[0]} container direction="column" alignItems="flex-start">
        <Link
          href={pathSN(barcode)}
          style={{ color: 'black', textDecoration: 'none' }}
        >
          <Grid item xs={12} container direction="column" alignItems="flex-start">
            {description.map((item: DescriptionText) => (
              <Typography
                key={item.text}
                variant="body1"
                className={item.bold ? styles.textBold : ''}
              >
                {item.text}
              </Typography>
            ))}
          </Grid>
        </Link>
      </Grid>
      <Grid item xs={columns[1]} style={{ textAlign: 'center' }}>
        <Switch
          name={barcode}
          checked={toggle}
          onChange={handleChange}
          color="primary"
        />
      </Grid>
    </Grid>
  );
};

export default PowerListItem;
