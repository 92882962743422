import { OSASensor } from './model';

// Low threshold = (position depth) - (position depth * Low%),
export const getLowThreshold = (item: OSASensor): number => {
  const { positionEmptyDepthMm, positionLowWarning } = item;
  return positionEmptyDepthMm - (positionEmptyDepthMm * Number(positionLowWarning));
};

export const isLowStockEnabled = (item: OSASensor): boolean => Number(item.positionLowWarning) > 0;

export const isOSAOutOfStock = (item: OSASensor): boolean => {
  const { currentState, positionEmptyDepthMm } = item;
  if (currentState) {
    return currentState.isEmpty || currentState.distanceMm >= positionEmptyDepthMm;
  }
  return false;
};

export const isOSALowStock = (item: OSASensor): boolean => {
  const { currentState } = item;
  if (currentState) {
    const lowThreshold = getLowThreshold(item);
    return currentState.distanceMm >= lowThreshold;
  }
  return false;
};

export const getStatusDesc = (item: OSASensor, i18n: Function): string => {
  const emptyStr = i18n({ id: 'devices.empty' });
  const lowStr = i18n({ id: 'devices.low' });
  if (isOSAOutOfStock(item)) {
    return emptyStr;
  }
  if (isOSALowStock(item)) {
    return lowStr;
  }
  return '';
};

export const isDisconnected = (item: OSASensor): boolean => {
  const ONE_HOUR = 3600000;
  const minimumTimeSinceLastUpdate = /* customersChoice || */ (ONE_HOUR * 72);
  // TODO user set time || 3 days
  const offlineTime = Date.now() - minimumTimeSinceLastUpdate;
  if (item.currentState) {
    return item.currentState.updateTimeMs <= offlineTime;
  }
  return false;
};

export const isProvisioned = (item: OSASensor): boolean => {
  if (!item.currentState) {
    return false;
  }
  return true;
};

export const isLowBattery = (item: OSASensor): boolean => {
  if (item.currentState) {
    return item.currentState.batteryCharge <= 10;
  }
  return false;
};

export const isIssue = (item: OSASensor): boolean => isDisconnected(item)
  || isLowBattery(item)
  || !isProvisioned(item);

export const emptyDuration = (item: OSASensor): number =>
  Date.now() - item.currentState.availChangeTimeMs;

export const getTimeSinceIssue = (timeInMs: number | undefined): string => {
  if (timeInMs) {
    const sec = timeInMs / 1000;
    if (sec >= (3600 * 24 * 14)) { // greater than or equal to 2 weeks
      return `${Math.round(sec / (3600 * 24 * 7))} weeks`;
    } if (sec >= (3600 * 48)) { // greater than or equal to 2 days
      return `${Math.round(sec / (3600 * 24))} days`;
    } if (sec >= (3600 * 2)) { // greater than or equal to 2 hours
      return `${Math.round(sec / 3600)} hours`;
    } if (sec >= 120) { // greater than or equal to 2 hours
      return `${Math.round(sec / 60)} minutes`;
    } if (sec >= 10) {
      return `${Math.round(sec)} seconds`;
    }
    return `< 10 seconds`;
  }
  return '';
};

export const getOSAStatus = (item: OSASensor): string => {
  const { positionLowWarning } = item;
  if (isOSAOutOfStock(item)) {
    return getTimeSinceIssue(emptyDuration(item));
  }
  if (isOSALowStock(item)) {
    return `${positionLowWarning}%`;
  }
  return '';
};

export const matchesFilterCriteria = (item: OSASensor, searchData: string): boolean => {
  const { positionInStore, merchandise: { name: merchandiseName } } = item;
  if (positionInStore?.name) {
    return merchandiseName.toUpperCase().includes(searchData.toUpperCase())
      || positionInStore.name.toUpperCase().includes(searchData.toUpperCase());
  }
  return merchandiseName.toUpperCase().includes(searchData.toUpperCase());
};

export const compTimeEmpty = (a: OSASensor, b: OSASensor): number => {
  const { currentState: { availChangeTimeMs: availChangeTimeMsOfA },
    positionLowWarning: positionLowWarningOfA } = a;
  const { currentState: { availChangeTimeMs: availChangeTimeMsOfB },
    positionLowWarning: positionLowWarningOfB } = b;

  if (isOSAOutOfStock(a) && isOSAOutOfStock(b)) {
    if (availChangeTimeMsOfA < availChangeTimeMsOfB) {
      return -1;
    } if (availChangeTimeMsOfA > availChangeTimeMsOfB) {
      return 1;
    }
  }
  if (isOSALowStock(a) && isOSALowStock(b)) {
    if (Number(positionLowWarningOfA) < Number(positionLowWarningOfB)) {
      return -1;
    } if (Number(positionLowWarningOfA) > Number(positionLowWarningOfB)) {
      return 1;
    }
  }
  return 0;
};
