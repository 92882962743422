import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { GridColumn } from 'common/models/material-ui';
import { Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { defaultColumns } from '../../../../../shared/ui/device-list/list-constants';
import { DescriptionText } from '../../../../../shared/ui/device-list/list-item/model';
import { barcodeToSNFormat } from '../../../../../shared/services/utils';
import { lockDevice, silenceDevice, unlockDevice } from '../../../secureDisplays.service';

const ERROR_DISPLAY_TIME = 4000; // milliseconds

interface SecureDisplayListItemProps {
  barcode: string;
  description: DescriptionText[];
  columns?: GridColumn[];
  classId: string;
}

const useStyle = makeStyles({
  textBold: {
    fontWeight: 'bold',
  },
  leftActionButton: {
    margin: '0 1.5rem 0 2px',
    minWidth: '50px',
    textTransform: 'capitalize',
  },
  actionButton: {
    minWidth: '50px',
    textTransform: 'capitalize',
  },
});

const SecureDisplayListItem = (props: SecureDisplayListItemProps): JSX.Element => {
  const styles = useStyle(props);
  const {
    classId, barcode, description, columns = defaultColumns,
  } = props;

  const { formatMessage: i18n } = useIntl();
  const lockStr = i18n({ id: 'devices.lock' });
  const silenceStr = i18n({ id: 'devices.silence' });
  const unlockStr = i18n({ id: 'devices.unlock' });

  const [waitingForLockdownResponse, setWaitingForLockdownResponse] = useState(false);
  const [waitingForUnlockdownResponse, setWaitingForUnlockdownResponse] = useState(false);
  const [waitingForSilenceResponse, setWaitingForSilenceResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const serialNumber = barcodeToSNFormat(barcode);

  const handleLockdownDevice = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    setWaitingForLockdownResponse(true);
    const response = await lockDevice(serialNumber, classId);
    if (response === 200) {
      setWaitingForLockdownResponse(false);
    } else {
      setErrorMessage('Command failed.');
      setWaitingForLockdownResponse(false);
      setTimeout(() => setErrorMessage(''), ERROR_DISPLAY_TIME);
    }
  };

  const handleUnlockdownDevice = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    setWaitingForUnlockdownResponse(true);
    const response = await unlockDevice(serialNumber, classId);
    if (response === 200) {
      setWaitingForUnlockdownResponse(false);
    } else {
      setErrorMessage('Command failed.');
      setWaitingForUnlockdownResponse(false);
      setTimeout(() => setErrorMessage(''), ERROR_DISPLAY_TIME);
    }
  };

  const handleSilenceAlarm = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    setWaitingForSilenceResponse(true);
    const response = await silenceDevice(serialNumber, classId);
    if (response === 200) {
      setWaitingForSilenceResponse(false);
    } else {
      setErrorMessage('Command failed.');
      setWaitingForSilenceResponse(false);
      setTimeout(() => setErrorMessage(''), ERROR_DISPLAY_TIME);
    }
  };

  return (
    <>
      <Grid container spacing={1} justify="space-between">
        <Grid item xs={columns[0]} container direction="column" alignItems="flex-start">
          {description.map((item: DescriptionText) => (
            <Typography noWrap key={item.text} variant="body1" className={item.bold ? styles.textBold : ''}>
              {item.text}
            </Typography>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3} justify="space-around" style={{ marginTop: '0.5rem' }}>
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <Button
            disabled={waitingForLockdownResponse}
            name={barcode}
            onClick={handleLockdownDevice}
            variant="contained"
            size="small"
            color="primary"
            className={styles.leftActionButton}
          >
            {lockStr}
          </Button>
          <Button
            disabled={waitingForUnlockdownResponse}
            name={barcode}
            onClick={handleUnlockdownDevice}
            variant="contained"
            size="small"
            color="primary"
            className={styles.actionButton}
          >
            {unlockStr}
          </Button>
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'center' }}>
          <Button
            disabled={waitingForSilenceResponse}
            name={barcode}
            onClick={handleSilenceAlarm}
            variant="contained"
            size="small"
            color="primary"
            className={styles.actionButton}
          >
            {silenceStr}
          </Button>
        </Grid>
        {errorMessage ? <Typography color="error">{errorMessage}</Typography> : null}

      </Grid>
    </>
  );
};

export default SecureDisplayListItem;
