import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { OSASensor } from 'modules/osa/model';
import ListItem from 'shared/ui/device-list/list-item';
import { DescriptionText, ItemStatus } from 'shared/ui/device-list/list-item/model';
import { barcodeToSNFormat } from '../../../../shared/services/utils';
import styles from './list-view.module.scss';

export interface OSAListViewItemData {
  description?: DescriptionText[];
  OSAData: OSASensor;
  status: ItemStatus;
  className?: string;
}

export interface OSAListViewProps {
  list: OSAListViewItemData[];
  headers?: string[];
}

const OSAListView: React.FC<OSAListViewProps> = ({
  list,
}: OSAListViewProps): JSX.Element => {
  const getDescriptionByOSAData = (item: OSASensor): DescriptionText[] =>
    [{ text: item.merchandise?.name || '', bold: true }, { text: item.positionInStore?.name || '' }];

  const pathSN = (item: OSASensor): string => `/osa/device/${barcodeToSNFormat(item.labelCode || '0000')}`;

  const renderListItem = ({ description, status, OSAData }: OSAListViewItemData): JSX.Element => (
    <Link
      to={{
        pathname: pathSN(OSAData),
      }}
      key={OSAData.labelCode}
      style={{ color: 'black', textDecoration: 'none' }}
    >
      <ListItem
        description={description || getDescriptionByOSAData(OSAData)}
        status={status}
      />
      <Divider />
    </Link>
  );

  return (
    <Container className={styles.container}>
      {list.map(renderListItem)}
    </Container>
  );
};

export default OSAListView;
