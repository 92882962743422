import React, { useEffect, useState, Fragment } from 'react';
import './index.module.scss';
import { useIntl } from 'react-intl';
import { Container, Button, IconButton, FormControl, Typography, List } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { getPositionsInStore } from '../../positionsInStore/positionsInStore.service';
import { PositionInStore } from '../../positionsInStore/model';

interface SelectPositionInStoreProps {
  nextStep: () => void;
  previousStep: () => void;
  positionInStoreValue: string;
  onSelectPosition: (name: string, department?: string) => void;
}

const initialPositions: PositionInStore[] = [];

const SelectPositionInStore = (props: SelectPositionInStoreProps): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const addNewPositionStr = i18n({ id: 'devices.addNewPosition' });
  const backStr = i18n({ id: 'devices.back' });
  const nextStr = i18n({ id: 'devices.next' });
  const selectPositionForDeviceStr = i18n({ id: 'devices.selectPositionForDevice' });
  let { positionInStoreValue } = props;

  const [positions, setPositions] = useState(initialPositions);
  const [filteredPositions, setFilteredPositions] = useState(initialPositions);
  const [filterValue, setFilterValue] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [selected, setSelected] = useState(positionInStoreValue);

  useEffect(() => {
    getPositionsInStore().then((values: PositionInStore[]) => {
      setPositions(values.sort((a, b) => a.name.localeCompare(b.name)));
      setFilteredPositions(values.sort((a, b) => a.name.localeCompare(b.name)));
    });
  }, []);

  const next = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    props.nextStep();
  };

  const back = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    props.previousStep();
  };

  const toggleIsSearchVisible = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setIsSearchVisible((prevState) => !prevState);
  };

  const filterOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // FIXME change to serverside filter?
    e.preventDefault();
    setFilterValue(e.target.value);
    const result = positions.filter((position) => position.name.includes(e.target.value));
    setFilteredPositions(result);
  };

  const handleClick = (position: PositionInStore): void => {
    const { name, department } = position;
    setSelected(name);
    positionInStoreValue = name;
    props.onSelectPosition(positionInStoreValue, department);
  };

  const isSelected = (name: string): boolean => selected === name;

  return (
    <Container disableGutters>
      <FormControl style={{ marginBottom: '5vw' }}>
        <Typography variant="body2" style={{ marginTop: '5vw', marginLeft: '3vw' }}>
          {`${selectPositionForDeviceStr}.`}
          <IconButton onClick={toggleIsSearchVisible} size="small" style={{ marginLeft: '0.5rem' }}>
            <SearchIcon fontSize="small" />
          </IconButton>
          <br />
          {`(${addNewPositionStr})`}
        </Typography>
        { isSearchVisible
          ? (
            <TextField
              autoFocus
              size="small"
              label="Search"
              id="search"
              type="search"
              style={{ borderRadius: '0', width: '90vw' }}
              value={filterValue}
              onChange={filterOnChange}
            />
          )
          : null }
      </FormControl>
      <List style={{ maxHeight: '100%', overflow: 'scroll', marginBottom: '10vh' }}>
        {
          filteredPositions.map((position) => {
            const isItemSelected = isSelected(position.name);
            return (
              <Fragment key={`${position.DOCUMENT_KEY}`}>
                <ListItem
                  onClick={(): void => {
                    handleClick(position);
                  }}
                  tabIndex={-1}
                  selected={isItemSelected}
                >
                  <ListItemText primary={position.name} />
                </ListItem>
                <Divider style={{ margin: '0' }} />
              </Fragment>
            );
          })
        }
      </List>
      <div style={{ backgroundColor: 'white', position: 'fixed', bottom: '0' }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ display: 'inline-block', borderRadius: '0', width: '50vw', marginLeft: '0', marginRight: '0' }}
          onClick={back}
        >
          {backStr}
        </Button>
        <Button
          disabled={!selected}
          variant="contained"
          color="primary"
          style={{ zIndex: 1000, display: 'inline-block', borderRadius: '0', width: '50vw', marginLeft: '0', marginRight: '0' }}
          onClick={next}
        >
          {nextStr}
        </Button>
      </div>
    </Container>
  );
};

export default SelectPositionInStore;
