import React, { useEffect, useRef } from 'react';
import quagga, { QuaggaJSResultCallbackFunction, QuaggaJSConfigObject } from '@ericblade/quagga2';
import './ReactQuagga.scss';

import { DEFAULT_CONFIG } from './utils';

interface ReactQuaggaProps {
  config?: QuaggaJSConfigObject;
  onDetected?: QuaggaJSResultCallbackFunction;
  onProcessed?: QuaggaJSResultCallbackFunction;
}

const ReactQuagga = ({
  config,
  onDetected: handleDetected,
  onProcessed: handleProcessed,
}: ReactQuaggaProps): JSX.Element => {
  const quaggaRef = useRef(null);

  useEffect(() => {
    const activeConfig = config || DEFAULT_CONFIG;
    const { inputStream: initAttrs } = activeConfig;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    initAttrs.target = quaggaRef.current;
    quagga.init(
      { ...activeConfig, inputStream: initAttrs },
      (error) => {
        if (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          return;
        }
        if (handleDetected) {
          quagga.onDetected(handleDetected);
        }
        if (handleProcessed) {
          quagga.onProcessed(handleProcessed);
        }
        quagga.start();
      },
    );
    return (): void => {
      quagga.offDetected(handleDetected);
      if (handleProcessed) {
        quagga.offProcessed(handleProcessed);
      }
      quagga.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={quaggaRef}
      className="video overlay__content"
    />
  );
};

export default ReactQuagga;
