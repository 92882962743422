import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { DeviceFailedToUpdate, TVPowerChangeResponse, TVwithCount } from '../../tv-sensors/model';
import { fetchTVData, powerOnAllTVs } from '../../tv-sensors/tvSensor.service';
import styles from '../../tv-sensors/list/views/list-view.module.scss';

const TVCard = (): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const issuesStr = i18n({ id: 'common.issues' });
  const turnOnTVsStr = i18n({ id: 'dashboard.turnOnTVs' });
  const tvsOffStr = i18n({ id: 'devices.tvsOff' });
  const tvOffStr = i18n({ id: 'devices.tvOff' });
  const viewTVSensorsStr = i18n({ id: 'dashboard.viewTVSensors' });
  const pleaseWaitStr = i18n({ id: 'common.pleaseWait' });

  const [tvSensorsCount, setTVSensorsCount] = useState(0);
  const [poweredOffTVsCount, setPoweredOffTVsCount] = useState(0);
  const [TVSensorsWithIssuesCount, setTVSensorsWithIssuesCount] = useState(0);
  const [TVIsLoading, setTVIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openPleaseWait, setOpenPleaseWait] = useState(false);

  const refreshSensorData = (): void => {
    setRefresh((prevState) => !prevState);
  };

  useEffect(() => {
    fetchTVData().then((tvStatusData: TVwithCount) => {
      setTVSensorsCount(tvStatusData.count.totalCount);
      setPoweredOffTVsCount(tvStatusData.count.poweredOffCount);
      setTVSensorsWithIssuesCount(tvStatusData.count.TVsWithIssuesCount);
    });
    setTVIsLoading(false);
    const refreshInterval = Number(sessionStorage.refresh_key);
    const timeoutInterval = setTimeout(refreshSensorData, refreshInterval);
    return (): void => clearTimeout(timeoutInterval);
  }, [refresh]);

  const deviceErrors: DeviceFailedToUpdate[] = [];

  const handleAllOn = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    setOpenPleaseWait(true);
    const response: TVPowerChangeResponse = await powerOnAllTVs();
    if (response) {
      if (response.erroredDevices.length) {
        response.erroredDevices.forEach((device) => {
          deviceErrors.push({
            merchandiseName: device.merchandise.name,
            labelCode: device.labelCode,
          });
        });
      }
    }
  };

  const handleClose = (): void => {
    setOpenPleaseWait(false);
  };

  const tvContent = tvSensorsCount > 0
    ? (
      <Card variant="outlined" style={{ width: '90vw', marginBottom: '5vw' }}>
        <CardContent>
          <Box display="flex" flexDirection="row" justifyContent="space-around">
            <Box>
              <Typography align="center">
                {poweredOffTVsCount}
              </Typography>
              <Typography align="center">
                {poweredOffTVsCount > 1 ? tvsOffStr : tvOffStr}
              </Typography>
            </Box>
            <Box>
              <Typography align="center">
                {TVSensorsWithIssuesCount}
              </Typography>
              <Typography align="center">
                {issuesStr}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <div style={{ position: 'relative', bottom: '0' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ display: 'inline-block', borderRadius: '0', width: '50%', marginLeft: '0', marginRight: '0' }}
            onClick={handleAllOn}
          >
            <Typography variant="overline" style={{ color: 'black' }}>{turnOnTVsStr}</Typography>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ display: 'inline-block', borderRadius: '0', width: '50%', marginLeft: '0', marginRight: '0' }}
          >
            <Typography variant="overline" style={{ color: 'black' }}>
              <Link color="inherit" underline="none" href="/tvs">
                {viewTVSensorsStr}
              </Link>
            </Typography>
          </Button>
        </div>
        <Dialog open={openPleaseWait}>
          <>
            <DialogTitle id="loading-dialog">{pleaseWaitStr}</DialogTitle>
            <Paper elevation={0} className={styles.progressDialog}>
              <CircularProgress />
              <Button color="secondary" onClick={handleClose}>Close</Button>
            </Paper>
          </>
        </Dialog>
      </Card>
    ) : null;

  return (
    <>
      {
        TVIsLoading
          ? (
            <div style={{ padding: '10vh' }}>
              <CircularProgress />
            </div>
          )
          : tvContent
      }
    </>
  );
};

export default TVCard;
