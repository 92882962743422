import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';

import { getLocale, getLocaleStrings } from 'common/i18n';
import Locale from 'common/i18n/locale/locale-model';

import App from 'modules/app';
import Login from 'modules/login';
import { defaultTheme } from 'themes';
import {
  history,
  store,
} from 'store';

import './index.scss';
import registerServiceWorker from './serviceWorkerSetup';

registerServiceWorker();

const RootApp = (): JSX.Element => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={defaultTheme}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route component={App} />
        </Switch>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
);

interface LocalizedAppProps {
  locale: string;
  messages: Record<string, string>;
}
const LocalizedApp = ({ locale, messages }: LocalizedAppProps): JSX.Element => (
  <IntlProvider locale={locale} messages={messages}>
    <RootApp />
  </IntlProvider>
);

const locale = getLocale();
getLocaleStrings(locale).then((localeValues: Locale) => {
  ReactDOM.render(<LocalizedApp locale={locale} messages={flatten(localeValues)} />, document.getElementById('root'));
});
