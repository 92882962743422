import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NoDeviceSetup from 'shared/ui/noDeviceSetup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Box, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import styles from './views/list-view.module.scss';
import AllSensors from './views/All';
import { fetchSecureDisplaysData } from '../secureDisplays.service';
import { SecuritySensor } from '../model';

const initialList: SecuritySensor[] = [];

const SecureDisplaysList = (): JSX.Element => {
  const [securityItems, setSecurityItems] = useState(initialList);
  const [tabIndex, setTabIndex] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { formatMessage: i18n } = useIntl();
  const allStr = i18n({ id: 'common.all' });
  const history = useHistory();

  const refreshInterval = Number(sessionStorage.refresh_key);

  const refreshSensorData = (): void => {
    setRefresh((prevState) => !prevState);
  };

  useEffect(() => {
    fetchSecureDisplaysData().then((items: SecuritySensor[]) => {
      setSecurityItems(items);
      setIsLoading(false);
      setTabIndex(0);
      // eslint-disable-next-line no-console
    }).catch((e) => console.error(e));
    const timeoutInterval = setTimeout(refreshSensorData, refreshInterval);
    return (): void => clearTimeout(timeoutInterval);
  }, [refresh, refreshInterval]);

  const back = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    history.goBack();
  };

  const renderSecuritySensorView = (visibleIndex: number, list: SecuritySensor[]): JSX.Element => {
    switch (visibleIndex) {
      default:
        return <AllSensors list={list} />;
    }
  };

  const SecuritySensorContent = securityItems.length
    ? (
      <>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          aria-label="Security Sensor Tabs"
          className={styles['tabs-container']}
        >
          <Tab data-testid="security-all-tab" label={allStr} />
        </Tabs>
        {renderSecuritySensorView(tabIndex, securityItems)}
      </>
    )
    : <NoDeviceSetup />;

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: '95%' }}
      >
        <IconButton onClick={back}>
          <ArrowBackIcon />
        </IconButton>
        <Typography style={{ fontWeight: 'bold', marginRight: '48px' }}>Secure Displays</Typography>
        <div />
      </Box>
      {isLoading
        ? (
          <div style={{ marginTop: '50vh' }}>
            <CircularProgress />
          </div>
        )
        : SecuritySensorContent}
    </>
  );
};

export default SecureDisplaysList;
