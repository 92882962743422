export interface TVSensor {
  labelCode: string;
  serialNum?: string;
  classId: string;
  positionInStore?: {
    name: string;
    department?: string;
  };
  merchandise: {
    code: string;
    name: string;
    brand?: string;
    deviceUPC?: string;
  };
  tenantId?: string;
  connectionStatus?: string;
  currentState: TVCurrentState;
  config?: TVConfig;
  isDeleted?: boolean;
}

export interface TVCount {
  totalCount: number;
  poweredOffCount: number;
  poweredOnCount: number;
  offlineCount: number;
  TVsWithIssuesCount: number;
}

export interface TVCurrentState {
  powerChangeTimeISO: string;
  powerChangeTimeMs: number;
  updateTimeISO: string;
  updateTimeMs: number;
  batteryValue: number;
  tempValue?: number;
  isOutputHdmiConnected?: boolean;
  isInputHdmiConnected?: boolean;
  decodedEDID?: DecodedEDID;
}

export interface DecodedEDID {
  EDIDVersionNumber: string;
  serialNumber: string;
  manufactureDate: string;
  manufacturer: string;
  productCode: string;
  tvDescriptorData: {
    displayProductName: string;
    displaySerialNumber: string;
  };
}

export interface TVConfig {
  nextScheduledCommand: TVNextCommand;
  tvState: TVState;
}

export interface TVNextCommand {
  day: string;
  hour: number;
  minute: number;
  isActive: boolean;
  desiredState: TVState;
}

export enum TVState {
  UNKNOWN = 'UNKNOWN',
  TURNING_OFF = 'TURNING_OFF',
  OFF = 'OFF',
  TURNING_ON = 'TURNING_ON',
  ON = 'ON',
}

export interface TVwithCount {
  count: TVCount;
  items: TVSensor[];
}

export interface BaseTV {
  serialNo: string;
  name: string;
  connectionStatus: string;
  batteryCharge: number;
  skuName: string;
}

export interface TVPowerStatus {
  tvState: TVState;
  poweredOffTimestamp?: string;
}

export interface TV extends BaseTV {
  TVPowerStatus: TVPowerStatus;
}

export interface TVManufacturer {
  code: string;
  name: string;
}

export interface TVPowerChangeResponse {
  erroredDevices: TVSensor[];
  recordsSuccessfullyUpdated: TVSensor[];
  updatesAttempted: TVSensor[];
}

export interface DeviceFailedToUpdate {
  merchandiseName: string;
  labelCode: string;
}

export interface Hours {
  day?: string;
  open: number;
  close: number;
  isClosed: boolean;
}

export interface BusinessHoursModel {
  DOCUMENT_KEY?: string;
  fri?: Hours;
  mon: Hours;
  sat?: Hours;
  sun?: Hours;
  thu?: Hours;
  tue?: Hours;
  wed?: Hours;
  storeId: string;
}
