import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import NoDeviceSetup from 'shared/ui/noDeviceSetup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import styles from './views/list-view.module.scss';
import AllTVs from './views/All';
import TVIssuesList from './views/Issues';
import TVsOffAndOn from './views/TVsOffAndOn';
import { fetchTVData, powerOffAllTVs, powerOnAllTVs } from '../tvSensor.service';
import { TVCount, TVwithCount, TVSensor, DeviceFailedToUpdate, TVPowerChangeResponse } from '../model';

const initialList: TVSensor[] = [];

const defaultItemsCount: TVCount = {
  totalCount: 0,
  poweredOffCount: 0,
  poweredOnCount: 0,
  offlineCount: 0,
  TVsWithIssuesCount: 0,
};

const TVList = (): JSX.Element => {
  const [itemsCount, setItemsCount] = useState(defaultItemsCount);
  const [TVItems, setTVItems] = useState(initialList);
  const [tabIndex, setTabIndex] = useState(0);
  const [openPleaseWait, setOpenPleaseWait] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { formatMessage: i18n } = useIntl();
  const allStr = i18n({ id: 'common.all' });
  const turnAllOffStr = i18n({ id: 'devices.turnAllOff' });
  const turnAllOnStr = i18n({ id: 'devices.turnAllOn' });
  const issuesStr = i18n({ id: 'common.issues' });
  const issueStr = i18n({ id: 'common.issue' });
  const pleaseWaitStr = i18n({ id: 'common.pleaseWait' });
  const tvsOffStr = i18n({ id: 'devices.tvsOff' });
  const issuesHeaderStr = itemsCount.TVsWithIssuesCount === 1 ? issueStr : issuesStr;
  const history = useHistory();

  const refreshInterval = Number(sessionStorage.refresh_key);

  const displayIndex = sessionStorage.getItem('tvTabIndex');

  const refreshSensorData = (): void => {
    setRefresh((prevState) => !prevState);
  };

  useEffect(() => {
    fetchTVData().then(({ count, items }: TVwithCount) => {
      setItemsCount(count);
      setTVItems(items);
      if (displayIndex != null) {
        setTabIndex(Number(displayIndex));
      } else if (count.poweredOffCount > 0 || count.poweredOnCount > 0) {
        setTabIndex(0);
      } else if (count.TVsWithIssuesCount > 0) {
        setTabIndex(1);
      } else {
        setTabIndex(2);
      }
      setIsLoading(false);
    });
    const timeoutInterval = setTimeout(refreshSensorData, refreshInterval);
    return (): void => clearTimeout(timeoutInterval);
  }, [refresh, displayIndex, refreshInterval]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    sessionStorage.setItem('tvTabIndex', String(newValue));
    setTabIndex(newValue);
  };

  const deviceErrors: DeviceFailedToUpdate[] = [];

  const handleAllOn = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    setOpenPleaseWait(true);
    const response: TVPowerChangeResponse = await powerOnAllTVs();
    if (response) {
      if (response.erroredDevices.length) {
        response.erroredDevices.forEach((device) => {
          deviceErrors.push({
            merchandiseName: device.merchandise.name,
            labelCode: device.labelCode,
          });
        });
      }
    }
  };

  const handleAllOff = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    setOpenPleaseWait(true);
    const response: TVPowerChangeResponse = await powerOffAllTVs();
    // response will include arrays of device update successes and failures
    if (response) {
      if (response.erroredDevices.length) {
        response.erroredDevices.forEach((device) => {
          deviceErrors.push({
            merchandiseName: device.merchandise.name,
            labelCode: device.labelCode,
          });
        });
      }
    }
  };

  const handleClose = (): void => {
    setOpenPleaseWait(false);
  };

  const back = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    history.goBack();
  };

  const renderTVView = (visibleIndex: number, list: TVSensor[]): JSX.Element => {
    switch (visibleIndex) {
      case 0:
        return <TVsOffAndOn list={list} refresh={refreshSensorData} />;
      case 1:
        return <TVIssuesList list={list} />;
      default:
        return <AllTVs list={list} />;
    }
  };

  const TVContent = TVItems.length
    ? (
      <>
        <Paper style={{ width: '85vw', margin: '1em', padding: '1em' }}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item>
              <Typography align="center">
                <span style={{ fontWeight: 'bold' }}>{`${itemsCount.poweredOffCount}/${itemsCount.totalCount}`}</span>
                <br />
                {tvsOffStr}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center">
                <span style={{ fontWeight: 'bold' }}>{itemsCount.TVsWithIssuesCount}</span>
                <br />
                {issuesHeaderStr}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ marginBottom: '1em' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: '40vw' }}
            onClick={handleAllOn}
          >
            {turnAllOnStr}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ width: '40vw' }}
            onClick={handleAllOff}
          >
            {turnAllOffStr}
          </Button>
        </div>
        <Tabs
          value={tabIndex}
          variant="fullWidth"
          indicatorColor="primary"
          onChange={handleChange}
          aria-label="TV Manager Tabs"
          className={styles['tabs-container']}
        >
          <Tab data-testid="tv-off-tab" label={`${tvsOffStr} (${itemsCount.poweredOffCount})`} />
          <Tab data-testid="tv-issues-tab" label={`${issuesStr} (${itemsCount.TVsWithIssuesCount})`} />
          <Tab data-testid="tv-all-tab" label={allStr} />
        </Tabs>
        {renderTVView(tabIndex, TVItems)}
        <Dialog open={openPleaseWait}>
          <>
            <DialogTitle id="loading-dialog">{pleaseWaitStr}</DialogTitle>
            <Paper elevation={0} className={styles.progressDialog}>
              <CircularProgress />
              <Button color="secondary" onClick={handleClose}>Close</Button>
            </Paper>
          </>
        </Dialog>
      </>
    )
    : <NoDeviceSetup />;

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: '95%' }}
      >
        <IconButton onClick={back}>
          <ArrowBackIcon />
        </IconButton>
        <Typography style={{ fontWeight: 'bold' }}>TV Managers</Typography>
        <IconButton href="/tv/schedule">
          <ScheduleIcon />
        </IconButton>
      </Box>
      {isLoading
        ? (
          <div style={{ marginTop: '50vh' }}>
            <CircularProgress />
          </div>
        )
        : TVContent}
    </>
  );
};

export default TVList;
