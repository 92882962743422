const TOKEN_KEY = 'token_key';
const TENANT_KEY = 'tenant_key';
const STORE_KEY = 'store_key';
const REFRESH_KEY = 'refresh_key';
const NOTIFICATION_REGISTRATION_KEY = 'registration_key';
const USER_KEY = 'user_key';

const getToken = (): string | null => sessionStorage.getItem(TOKEN_KEY);

const setToken = (token: string): void => {
  sessionStorage.setItem(TOKEN_KEY, token);
};

const getTenant = (): string | null => sessionStorage.getItem(TENANT_KEY);

const setTenant = (tenant: string): void => {
  sessionStorage.setItem(TENANT_KEY, tenant);
};

const setUser = (userName: string): void => {
  sessionStorage.setItem(USER_KEY, userName);
};

const getUser = (): string | null => {
  return sessionStorage.getItem(USER_KEY);
};

const getStore = (): string | null => sessionStorage.getItem(STORE_KEY);

const setStore = (storeId: string): void => {
  sessionStorage.setItem(STORE_KEY, storeId);
};

const setRefreshInterval = (refreshInterval: string): void =>
  sessionStorage.setItem(REFRESH_KEY, refreshInterval);

const setRegistrationToken = (data: string): void =>
  localStorage.setItem(NOTIFICATION_REGISTRATION_KEY, data);

const getRegistrationToken = (): string | null => localStorage.getItem(NOTIFICATION_REGISTRATION_KEY);

export {
  getToken,
  setToken,
  getTenant,
  setTenant,
  getStore,
  setStore,
  setRefreshInterval,
  setRegistrationToken,
  getUser,
  setUser,
  getRegistrationToken,
};
