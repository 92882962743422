import React, { useEffect, useState } from 'react';
import { Container, Typography, Divider, Grid, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';
import { TVSensor, TVManufacturer, TVPowerChangeResponse } from '../model';
import { getTVManufacturerName, powerOffTV, powerOnTV, refreshEdidData } from '../tvSensor.service';
import { barcodeToSNFormat } from '../../../shared/services/utils';

const RESPONSE_TIMEOUT = 70000; // milliseconds

interface TVDeviceViewDetailsProps {
  sensorData: TVSensor;
  refresh: () => void;
}

const initialManufacturerData = {
  code: '',
  name: '',
};

const TVDeviceViewTVData = ({ sensorData, refresh }: TVDeviceViewDetailsProps): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const pleaseWaitStr = i18n({ id: 'common.pleaseWait' });
  const timeoutError = i18n({ id: 'common.timeoutError' });

  const [manufacturerData, setManufacturerData] = useState(initialManufacturerData);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [tvErrorMessage, setTvErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const edid = sensorData.currentState?.decodedEDID;
  const manufacturerCode = edid?.manufacturer || 'UNK';
  const tvDisplayProduct = edid?.tvDescriptorData
    ? edid?.tvDescriptorData.displayProductName : '';
  const tvDisplaySerNum = edid?.tvDescriptorData
    ? edid?.tvDescriptorData.displaySerialNumber : '';
  const tvManufactureDate = edid?.manufactureDate;
  const tvProductCode = edid?.productCode;
  const EDIDVersion = edid?.EDIDVersionNumber;
  const vendorSerNum = edid?.serialNumber;

  const sensorSerialNumber = barcodeToSNFormat(sensorData.labelCode);

  useEffect(() => {
    getTVManufacturerName(manufacturerCode).then((values: TVManufacturer) => {
      setManufacturerData(values);
      setIsLoading(false);
    });
  }, [manufacturerCode]);

  const manufacturerName = manufacturerData.name;

  const renderProduct = tvDisplayProduct ? (
    <>
      <Grid item xs>
        <Typography className={styles['data-title']}>Product:</Typography>
      </Grid>
      <Grid item xs>
        <Typography>{tvDisplayProduct}</Typography>
      </Grid>
      <Divider />
    </>
  ) : null;

  const renderTVSerialNumber = tvDisplaySerNum ? (
    <>
      <Grid item xs>
        <Typography className={styles['data-title']}>Serial Number:</Typography>
      </Grid>
      <Grid item xs>
        <Typography>{tvDisplaySerNum}</Typography>
      </Grid>
      <Divider />
    </>
  ) : null;

  const renderManufactureDate = tvManufactureDate ? (
    <>
      <Grid item xs>
        <Typography className={styles['data-title']}>Manufacture Date:</Typography>
      </Grid>
      <Grid item xs>
        <Typography>{tvManufactureDate}</Typography>
      </Grid>
      <Divider />
    </>
  ) : null;

  const renderProductCode = tvManufactureDate ? (
    <>
      <Grid item xs>
        <Typography className={styles['data-title']}>Product Code:</Typography>
      </Grid>
      <Grid item xs>
        <Typography>{tvProductCode}</Typography>
      </Grid>
      <Divider />
    </>
  ) : null;

  const renderEDIDVersion = tvManufactureDate ? (
    <>
      <Grid item xs>
        <Typography className={styles['data-title']}>EDID Version:</Typography>
      </Grid>
      <Grid item xs>
        <Typography>{EDIDVersion}</Typography>
      </Grid>
      <Divider />
    </>
  ) : null;

  const renderVendorSerNum = vendorSerNum ? (
    <>
      <Grid item xs>
        <Typography className={styles['data-title']}>Vendor Serial Number:</Typography>
      </Grid>
      <Grid item xs>
        <Typography>{vendorSerNum}</Typography>
      </Grid>
      <Divider />
    </>
  ) : null;

  const renderManufacturerName = isLoading ? (
    <div>
      <CircularProgress />
    </div>
  )
    : (<Typography>{manufacturerName}</Typography>);

  const getEdid = async (): Promise<void> => {
    await refreshEdidData(sensorSerialNumber);
    refresh();
  };

  // handle power changes via UI switch
  const handleOn = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    setOpen(true);
    setWaitingForResponse(true);
    const responseTimeout = setTimeout(() => {
      setWaitingForResponse(false);
      setTvErrorMessage(timeoutError);
    }, RESPONSE_TIMEOUT);
    const response: TVPowerChangeResponse = await powerOnTV(sensorSerialNumber);
    if (response) {
      clearTimeout(responseTimeout);
      setWaitingForResponse(false);
      if (response.erroredDevices.length > 0) {
        setTvErrorMessage(
          `Update failed for ${response.erroredDevices[0].merchandise.name}. Please check the connections and try again.`,
        );
      } else {
        setSuccessMessage('Command received.');
      }
      refresh();
    }
  };

  const handleOff = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    setOpen(true);
    setWaitingForResponse(true);
    const responseTimeout = setTimeout(() => {
      setWaitingForResponse(false);
      setTvErrorMessage(timeoutError);
    }, RESPONSE_TIMEOUT);
    const response: TVPowerChangeResponse = await powerOffTV(sensorSerialNumber);
    if (response) {
      clearTimeout(responseTimeout);
      setWaitingForResponse(false);
      if (response.erroredDevices.length > 0) {
        setTvErrorMessage(
          `Update failed for ${response.erroredDevices[0].merchandise.name}. Please check the connections and try again.`,
        );
      } else {
        setSuccessMessage('Command received.');
      }
      refresh();
    }
  };

  const handleClose = (): void => {
    setOpen(false);
    setTvErrorMessage('');
    setSuccessMessage('');
  };

  return (
    <Container style={{ marginTop: '1em' }}>
      {sensorData.currentState?.decodedEDID ? (
        <>
          <Button color="primary" onClick={getEdid}>
            Refresh Data
          </Button>
          <Divider />
          <Grid container spacing={1}>
            <Grid item xs>
              <Button color="primary" variant="contained" onClick={handleOn}>
                Turn On
              </Button>
            </Grid>
            <Grid item xs>
              <Button color="secondary" variant="outlined" onClick={handleOff}>
                Turn Off
              </Button>
            </Grid>
            <Dialog open={open}>
              <DialogTitle id="loading-dialog">{waitingForResponse ? pleaseWaitStr : null}</DialogTitle>
              <Paper elevation={0} className={styles.progressDialog}>
                { waitingForResponse ? <CircularProgress /> : null }
                { tvErrorMessage ? (
                  <>
                    <Typography color="error" className={styles['notification-message']}>{tvErrorMessage}</Typography>
                    <Button onClick={handleClose}>Close</Button>
                  </>
                ) : null }
                { successMessage ? (
                  <>
                    <Typography className={styles['notification-message']}>{successMessage}</Typography>
                    <Button onClick={handleClose}>Close</Button>
                  </>
                ) : null}
              </Paper>
            </Dialog>
            <Divider />
            <Grid item xs>
              <Typography className={styles['data-title']}>Manufacturer Code:</Typography>
            </Grid>
            <Grid item xs>
              <Typography>{manufacturerCode}</Typography>
            </Grid>
            <Divider />
            <Grid item xs>
              <Typography className={styles['data-title']}>Manufacturer:</Typography>
            </Grid>
            <Grid item xs>
              {renderManufacturerName}
            </Grid>
            <Divider />
            {renderManufactureDate}
            {renderProduct}
            {renderTVSerialNumber}
            {renderEDIDVersion}
            {renderProductCode}
            {renderVendorSerNum}
          </Grid>
        </>
      ) : <pre style={styles.containerEmptyText}>TV Data Not Available</pre>}
    </Container>
  );
};

export default TVDeviceViewTVData;
