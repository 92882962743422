import { useEffect, useState } from 'react';

const mediaDeviceSupported = (): boolean => {
  const { mediaDevices } = navigator;
  return mediaDevices && (typeof mediaDevices.getUserMedia === 'function');
};

const getVideoDevices = async (): Promise<MediaDeviceInfo[]> => {
  if (!mediaDeviceSupported()) return [];
  const videoDevices: MediaDeviceInfo[] = [];
  const devices = await navigator.mediaDevices.enumerateDevices();
  devices.forEach((device) => {
    if (device.kind === 'videoinput') {
      videoDevices.push(device);
    }
  });
  return videoDevices;
};

export const useQuagga = (): boolean => {
  const [scannerSupported, setScannerSupported] = useState(false);

  useEffect(() => {
    getVideoDevices()
      .then((devices) => setScannerSupported(devices.length > 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return scannerSupported;
};

export const DEFAULT_CONFIG = {
  inputStream: {
    name: 'Live',
    type: 'LiveStream',
    constraints: {
      width: 640,
      height: 380,
      facingMode: 'environment',
    },
    area: { // defines rectangle of the detection/localization area
      top: '0%', // top offset
      right: '0%', // right offset
      left: '0%', // left offset
      bottom: '0%', // bottom offset
    },
    singleChannel: false,
  },
  decoder: {
    readers: [
      'code_128_reader',
      'upc_reader',
    ],
    debug: {
      drawBoundingBox: true,
      showFrequency: false,
      drawScanline: true,
      showPattern: false,
    },
    multiple: false,
  },
  numOfWorkers: navigator.hardwareConcurrency || 4,
  frequency: 5,
  locate: false,
  locator: {
    patchSize: 'medium',
    halfSample: true,
  },
};
