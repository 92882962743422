import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Device } from '../model';

interface DeviceListProps {
  devices: Device[];
}

// TODO - modify the UI according to UX
const DeviceList = ({ devices }: DeviceListProps): JSX.Element => (
  <>
    {
      devices.map((device: Device) => (
        <Card variant="outlined" key={device.id || device.friendlyName}>
          <CardContent>
            {device.friendlyName}
          </CardContent>
        </Card>
      ))
    }
  </>
);

export default DeviceList;
