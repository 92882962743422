import httpClient from 'shared/services/http-client';
import { Merchandise } from './model';
import { endpoints } from '../../shared/services/endpoints.constants';

const getMerchandise = async (): Promise<Merchandise[]> => {
  const response = await httpClient.get(endpoints.MERCHANDISE_SORT_BY_NAME);
  return response.data;
};

const getMerchandiseByCode = async (merchandiseCode: string): Promise<Merchandise[]> => {
  const url = endpoints.MERCHANDISE_BY_CODE
    .replace('{merchCode}', merchandiseCode);
  const response = await httpClient.get(url);
  return response.data;
};

const getMerchanidseByDeviceUpc = async (deviceUPC: string): Promise<Merchandise[]> => {
  const url = endpoints.MERCHANDISE_BY_DEVICEUPC
    .replace('{deviceUPC}', deviceUPC);
  const response = await httpClient.get(url);
  return response.data;
};

const searchMerchandiseByName = async (merchandiseName: string): Promise<Merchandise[]> => {
  const url = endpoints.MERCHANDISE_SEARCH_BY_NAME
    .replace('{merchName}', merchandiseName);
  const response = await httpClient.get(url);
  return response.data;
};

const searchMerchandiseBycode = async (merchandiseCode: string): Promise<Merchandise[]> => {
  const url = endpoints.MERCHANDISE_SEARCH_BY_CODE
    .replace('{merchCode}', merchandiseCode);
  const response = await httpClient.get(url);
  return response.data;
};

export {
  getMerchandise,
  getMerchandiseByCode,
  searchMerchandiseBycode,
  searchMerchandiseByName,
  getMerchanidseByDeviceUpc,
};
