import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Container, Typography, Divider, Grid } from '@material-ui/core';
import BatteryStdIcon from '@material-ui/icons/BatteryStd';
import { convertUnitValues, getPositionDepthUnits, METRIC } from 'modules/devices/add-device/constants';
import { SelectField } from 'modules/devices/add-device/SelectField';
import styles from './index.module.scss';
import { OSASensor } from '../model';
import { isOSAOutOfStock, isDisconnected, isLowBattery, isProvisioned, emptyDuration } from '../osa-util';
import { dateToString, msAsDuration } from '../../../shared/services/utils';

interface OSADeviceViewDetailsProps {
  sensorData: OSASensor;
}

export const NOT_AVAILABLE = 'N/A';

/* eslint-disable no-param-reassign */
const OSADeviceViewDetails = ({ sensorData }: OSADeviceViewDetailsProps): JSX.Element => {
  const intl = useIntl();
  const { formatMessage: i18n } = intl;
  const offlineStr = i18n({ id: 'common.offline' });
  const lastUpdateStr = i18n({ id: 'devices.lastUpdate' });
  const positionStr = i18n({ id: 'common.positionInStore' });
  const merchandiseStr = i18n({ id: 'merchandise.merchandise' });
  const positionEmptyStr = i18n({ id: 'devices.positionEmpty' });
  const merchandiseAvailableStr = i18n({ id: 'devices.merchandiseAvailable' });
  const deviceNumberStr = i18n({ id: 'devices.deviceNumber' });
  const positionDepthStr = i18n({ id: 'devices.positionDepth' });
  const positionLowWarningStr = i18n({ id: 'osaSettings.positionLowWarning' });
  const scanIntervalStr = i18n({ id: 'devices.scanInterval' });
  const deviceTypeStr = i18n({ id: 'devices.deviceType' });
  const newStr = i18n({ id: 'common.new' });
  const minutesStr = i18n({ id: 'common.minutes' });
  const batteryStatusStr = i18n({ id: 'devices.batteryStatus' });
  const hoursStr = i18n({ id: 'common.hours' });
  const daysStr = i18n({ id: 'common.days' });
  const brandStr = i18n({ id: 'common.brand' });
  const departmentStr = i18n({ id: 'common.department' });

  const deviceType = sensorData.classId === 'osa' ? 'On-Shelf Availability Sensor' : 'Check Device';

  const lastReportTime = isProvisioned(sensorData)
    ? dateToString(new Date(sensorData.currentState.updateTimeISO))
    : null;

  let issueDurationTime = '';
  let merchandiseStatusStr;
  if (!isProvisioned(sensorData)) {
    merchandiseStatusStr = newStr;
  } else if (isDisconnected(sensorData)) {
    const timeSinceOffline = (Date.now() - sensorData.currentState.updateTimeMs);
    merchandiseStatusStr = `${offlineStr}:`;
    issueDurationTime = msAsDuration(timeSinceOffline, daysStr, hoursStr);
  } else if (isOSAOutOfStock(sensorData)) {
    merchandiseStatusStr = `${positionEmptyStr}:`;
    issueDurationTime = msAsDuration(emptyDuration(sensorData), daysStr, hoursStr);
  } else {
    merchandiseStatusStr = merchandiseAvailableStr;
  }

  const batteryStatus = isLowBattery(sensorData)
    ? <BatteryStdIcon color="error" />
    : <BatteryStdIcon style={{ color: 'green' }} />;

  const measurementIntervalSec = sensorData.currentState?.measurementIntervalSec
    ? `${sensorData.currentState.measurementIntervalSec / 60} ${minutesStr}`
    : `5 ${minutesStr}`; // FIXME when scan data arrives

  const [positionDepthUnits, setPositionDepthUnits] = useState(METRIC);

  const handleUnitChange = (unitValue: string): void => {
    const existingValue = sensorData.positionEmptyDepthMm;
    const convertedValue = convertUnitValues(existingValue, positionDepthUnits, unitValue);
    sensorData.positionEmptyDepthMm = Math.round(convertedValue);
    setPositionDepthUnits(unitValue);
  };

  return (
    <Container style={{ marginTop: '2em' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography noWrap style={{ textTransform: 'capitalize' }}>
            {merchandiseStatusStr}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography noWrap>
            {issueDurationTime}
          </Typography>
        </Grid>
        <Grid item xs={6} hidden>
          <Typography className={styles['battery-icon']}>
            {!isDisconnected(sensorData)
              ? `${batteryStatusStr}:` : null}
          </Typography>
        </Grid>
        <Grid item xs={6} hidden>
          <Typography className={styles['battery-icon']}>
            {!isDisconnected(sensorData)
              ? batteryStatus : null}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="left" style={{ textTransform: 'capitalize' }}>
            {lastReportTime ? `${lastUpdateStr}:` : ''}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{lastReportTime}</Typography>
        </Grid>
        <Divider />
        <Grid item xs={6}>
          <Typography>{`${deviceTypeStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{deviceType}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${deviceNumberStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{sensorData.labelCode}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${merchandiseStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {sensorData.merchandise?.name}
            <br />
            {sensorData.merchandise?.code?.trim() || NOT_AVAILABLE}
            <br />
            {sensorData.merchandise?.deviceUPC?.trim() || NOT_AVAILABLE}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${brandStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {sensorData?.merchandise?.brand?.trim() || NOT_AVAILABLE}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${positionStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{sensorData.positionInStore?.name}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${departmentStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{sensorData.positionInStore?.department?.trim() || NOT_AVAILABLE}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${positionDepthStr}:`}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography>{sensorData.positionEmptyDepthMm}</Typography>
        </Grid>
        <Grid item xs={1}>
          <SelectField
            items={getPositionDepthUnits(intl)}
            value={positionDepthUnits}
            onChange={handleUnitChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${positionLowWarningStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{sensorData.positionLowWarning !== undefined ? `${sensorData.positionLowWarning}%` : 'N/A'}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${scanIntervalStr}:`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{measurementIntervalSec}</Typography>
        </Grid>
      </Grid>

    </Container>
  );
};

export default OSADeviceViewDetails;
