import React, { useEffect, useState, Fragment } from 'react';
import { useIntl } from 'react-intl';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, List, Box, IconButton } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { getMillisecondsToStringFormatValue } from '../../../shared/services/utils';
import { fetchBusinessHours } from '../tvSensor.service';
import { BusinessHoursModel, Hours } from '../model';

const daysOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const initialBusinessHours: Hours[] = [];
const militaryTimeMomentFormat = 'hh:mmA';
const tenMinuteMilisecond = 600000;
const fiveMinuteMilisecond = 300000;

const useStyles = makeStyles({
  scheduleList: {
    maxHeight: '350px',
    overflow: 'auto',
    marginBottom: '10vh',
  },
});

const TVPowerSchedule = (): JSX.Element => {
  const classes = useStyles();
  const { formatMessage: i18n } = useIntl();
  const tvOnTimeStr = i18n({ id: 'devices.tvAutoOnMessage' });
  const tvOffTimeStr = i18n({ id: 'devices.tvAutoOffMessage' });
  const tvScheduleStr = i18n({ id: 'devices.tvSchedule' });
  const storeCloseStr = i18n({ id: 'storeSettings.closed' });
  const checkAdminTomakeChangeStr = i18n({ id: 'storeSettings.checkAdminTochangeSetting' });
  const history = useHistory();
  const [businessHours, setBusinessHours] = useState(initialBusinessHours);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const processBusinessHours = (businessHour: any): Hours[] => {
    let dataDay = '';
    const finalData: Hours[] = [];
    daysOrder.forEach((day) => {
      const dataRow = {
        day: '',
        open: 0,
        close: 0,
        isClosed: false,
      };
      dataDay = day.substring(0, 3).toLowerCase();

      dataRow.day = day.toUpperCase();
      dataRow.open = businessHour[dataDay].open;
      dataRow.close = businessHour[dataDay].close;
      dataRow.isClosed = businessHour[dataDay].isClosed;

      finalData.push(dataRow);
    });
    return finalData;
  };

  useEffect(() => {
    fetchBusinessHours().then((values: BusinessHoursModel[]) => {
      setBusinessHours(processBusinessHours(values[0]));
    });
  }, []);

  const getStoreTiming = (business: Hours): string => {
    if (business.isClosed === true) {
      return storeCloseStr;
    }

    const openTime = moment(getMillisecondsToStringFormatValue(business.open - tenMinuteMilisecond),
      militaryTimeMomentFormat).format(militaryTimeMomentFormat);
    const closeTime = moment(getMillisecondsToStringFormatValue(business.close
      + fiveMinuteMilisecond), militaryTimeMomentFormat).format(militaryTimeMomentFormat);

    return `${openTime}-${closeTime}`;
  };

  const back = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    history.goBack();
  };

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: '95%' }}
      >
        <IconButton onClick={back}>
          <ArrowBackIcon />
        </IconButton>
        <Typography style={{ fontSize: 'larger', margin: '0 auto', paddingRight: '48px' }}>
          {`${tvScheduleStr}`}
        </Typography>
      </Box>
      <Typography align="center">
        {`${tvOnTimeStr}`}
      </Typography>
      <Typography align="center">
        {`${tvOffTimeStr}`}
      </Typography>
      <List component="div" disablePadding className={classes.scheduleList}>
        {
        businessHours.map((business) => {
          const timing = getStoreTiming(business);
          return (
            <>
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography style={{ fontWeight: 'bold' }}>
                      {`${business.day}`}
                    </Typography>
                  )}
                />
                <ListItemText style={{ textAlign: 'right', paddingRight: '10%' }} primary={timing} />
              </ListItem>
              <Divider style={{ margin: '0' }} />
            </>
          );
        })
        }
      </List>
      <Typography align="center">
        {`${checkAdminTomakeChangeStr}`}
      </Typography>
    </Container>
  );
};

export default TVPowerSchedule;
