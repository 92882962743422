import React, { useState } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { getToken } from 'common/services/token.service';
import Layout from 'modules/layout';
import { SearchContext } from './Context';

const App = ({ location }: RouteComponentProps): JSX.Element => {
  const isToken = !!getToken();
  const { pathname, search } = location;
  const [searchValue, setSearchValue] = useState('');

  return (
    <div>
      {!isToken
        ? (
          <Redirect to={{
            pathname: '/login',
            search: `?redirect=${pathname + search}`,
          }}
          />
        )
        : (
          <SearchContext.Provider
            value={{ searchCriteria: searchValue, updateSearch: setSearchValue }}
          >
            <Layout />
          </SearchContext.Provider>
        )}
    </div>
  );
};
export default App;
