import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { OSASensor } from 'modules/osa/model';
import { ListItemErrorStatus } from 'shared/ui/device-list/list-item/model';
import {
  isOSAOutOfStock, isDisconnected, isLowBattery, isProvisioned,
  matchesFilterCriteria,
} from 'modules/osa/osa-util';
import { intlCompareStrings } from '../../../../shared/services/utils';
import { ListViewProps } from './model';
import OSAListView, { OSAListViewItemData } from './list-view';
import { SearchContext } from '../../../app/Context';

const AllOSA = ({ list }: ListViewProps): JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const { formatMessage: i18n } = useIntl();
  const lowBatteryStr = i18n({ id: 'common.lowBattery' });
  const offlineStr = i18n({ id: 'common.offline' });
  const installPendingStr = i18n({ id: 'devices.installPending' });
  const emptyStr = i18n({ id: 'devices.empty' });

  const mapOSAtoListItemData = (items: OSASensor[]): OSAListViewItemData[] => items
    .filter((item: OSASensor) => matchesFilterCriteria(item, searchCriteria))
    .sort((a, b) =>
      intlCompareStrings(a.merchandise.name, b.merchandise.name))
    .map((item: OSASensor) => {
      let statusText = '';
      let statusTextNumber = 0;
      if (isDisconnected(item)) {
        statusText = `${offlineStr}\n`;
        statusTextNumber += 1;
      } else {
        if (!isProvisioned(item)) {
          statusText = installPendingStr;
          statusTextNumber += 1;
        }
        if (isOSAOutOfStock(item)) {
          statusText += `${emptyStr}\n`;
          statusTextNumber += 1;
        }
        if (isLowBattery(item) && statusTextNumber < 2) {
          statusText += (`${lowBatteryStr}\n`);
          statusTextNumber += 1;
        }
      }
      const status = {
        text: statusText,
        errorStatus: statusText
          ? ListItemErrorStatus.warning
          : ListItemErrorStatus.None,
      };
      return {
        OSAData: item,
        status,
      };
    });

  return <OSAListView list={mapOSAtoListItemData(list)} />;
};

export default AllOSA;
