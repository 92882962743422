import React, { useState, useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { TextField, Box } from '@material-ui/core';
import { SearchContext } from '../../../modules/app/Context';

const SearchField = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { searchCriteria, updateSearch } = useContext(SearchContext);

  const search = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    updateSearch('');
    setIsOpen((prevState) => !prevState);
  };

  const filterOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    updateSearch(e.target.value);
  };

  const handleClose = (): void => {
    updateSearch('');
    setIsOpen(false);
  };

  return (
    <>
      <IconButton onClick={search}>
        <SearchIcon />
      </IconButton>
      {isOpen
        ? (
          <Box
            style={{
              width: '80%',
              maxWidth: '300px',
              position: 'fixed',
              margin: 'auto',
              top: '5%',
              right: '10%',
              zIndex: 300,
              opacity: 1,
              backgroundColor: 'white',
              border: '0.25px solid grey',
            }}
          >
            <TextField
              autoFocus
              size="small"
              label="Search"
              id="search"
              style={{ marginTop: '3%', marginBottom: '4px', width: '75%' }}
              value={searchCriteria}
              onChange={filterOnChange}
            />
            <IconButton size="small" onClick={handleClose} style={{ paddingTop: '5%' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        ) : null}
    </>
  );
};

export default SearchField;
