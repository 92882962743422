import React, { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';

interface AddDeviceStepperProps {
  currentStepName: string;
  currentStepNumber: number;
  nextStepName: string;
  numberOfSteps: number;
}

const AddDeviceStepper = (props: AddDeviceStepperProps): JSX.Element => {
  const { currentStepName, currentStepNumber, nextStepName, numberOfSteps } = props;
  const { formatMessage: i18n } = useIntl();
  const nextStepStr = i18n({ id: 'devices.nextStep' });
  const stepNumberStr = i18n({ id: 'devices.stepNumber' }, { currentStep: (currentStepNumber + 1), totalSteps: numberOfSteps });

  function nextStep(): ReactElement {
    if (currentStepNumber < (numberOfSteps - 1)) {
      return (
        <>
          <Typography variant="caption" display="block">
            {`${nextStepStr}:`}
          </Typography>
          <Typography variant="overline">
            {nextStepName}
          </Typography>
        </>
      );
    }
    return (<div />);
  }

  return (
    <Paper elevation={0}>
      <Grid container spacing={3} direction="row" alignItems="center" justify="space-between" style={{ padding: '3vw' }}>
        <Grid item>
          <Typography variant="caption" display="block">
            {stepNumberStr}
          </Typography>
          <Typography variant="overline">
            {currentStepName}
          </Typography>
        </Grid>
        <Grid item>
          {nextStep()}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddDeviceStepper;
