import React, { useState, useEffect } from 'react';
import { Button, Grid, Link } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import RouterOutlinedIcon from '@material-ui/icons/RouterOutlined';
import WifiIcon from '@material-ui/icons/Wifi';
import CropFreeIcon from '@material-ui/icons/CropFree';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';
import { getDevices } from '../devices/devices.service';
import { Device } from '../devices/model';
import StatusList from './status-list/StatusList';

const initialDevices: Device[] = [];

const LiveDashboard = (): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const noDevicesSetupStr = i18n({ id: 'devices.noDevicesSetup' });
  const scanDeviceStr = i18n({ id: 'devices.scanDevice' });

  const [devices, setDevices] = useState(initialDevices);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDevices().then((values: Device[]) => {
      if (values) {
        setDevices(values);
      }
      setIsLoading(false);
      // eslint-disable-next-line no-console
    }).catch((e) => console.error(e));
  }, []);

  const deviceContent = devices.length
    ? (
      <StatusList />
    )
    : (
      <div className={styles['text-message-wrapper']}>
        <pre style={{ fontFamily: 'inherit' }}>
          {noDevicesSetupStr}
        </pre>
      </div>
    );

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="baseline"
        style={{ width: '95vw', margin: '5vw 0vw 5vw 2vw' }}
      >
        <Grid item>
          <IconButton disabled style={{ padding: '2vw' }}>
            <RouterOutlinedIcon style={{ fontSize: '8vw' }} />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton disabled style={{ padding: '2vw', marginRight: '4vw' }}>
            <WifiIcon style={{ fontSize: '8vw' }} />
          </IconButton>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" style={{ padding: '0, 3rem', marginLeft: '10vw' }}>
            <CropFreeIcon />
            <Link href="/devices/scan" style={{ color: 'white', margin: '0.25rem' }}>{scanDeviceStr}</Link>
          </Button>
        </Grid>
      </Grid>
      {
        isLoading
          ? <CircularProgress />
          : deviceContent
      }
    </>
  );
};

export default LiveDashboard;
