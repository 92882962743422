import httpClient from 'shared/services/http-client';
import { Device, StagingDevice, OsaSensorSetting } from './model';
import { endpoints } from '../../shared/services/endpoints.constants';

const getDevices = async (): Promise<Device[]> => {
  const tenantId = sessionStorage.tenant_key;
  const storeId = sessionStorage.store_key;
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = endpoints.PROVISIONED_DEVICES
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url);
  return response.data;
};

const getDefaultOsaSensorSettings = async (): Promise<OsaSensorSetting[]> => {
  const url = endpoints.OSA_SENSOR_SETTING
    .replace('{storeId}', sessionStorage.store_key);
  const response = await httpClient.get(url);
  return response.data;
};

const getDeviceTenant = async (serialNumber: string): Promise<StagingDevice> => {
  const url = endpoints.ALL_DEVICES_BY_SERIAL_NUMBER
    .replace('{serNum}', serialNumber);
  const response = await httpClient.get(url);
  return response.data.tenantId;
};

const getDeviceBySN = async (serialNumber: string): Promise<Device> => {
  const url = endpoints.PROVISIONED_DEVICE_BY_SERIAL_NUMBER
    .replace('{serNum}', serialNumber);
  const response = await httpClient.get(url);
  return response.data;
};

const saveDeviceBySN = async (serialNumber: string, data: object): Promise<unknown> => {
  // if device exists in staging put else post
  let saveToStaging;
  const stagingData = {
    tenantId: sessionStorage.tenant_key,
  };
  const stagingDeviceUrl = endpoints.ALL_DEVICES_BY_SERIAL_NUMBER
    .replace('{serNum}', serialNumber);
  const getStagingResponse = await httpClient.get(stagingDeviceUrl);
  if (getStagingResponse.data) {
    saveToStaging = await httpClient.put(stagingDeviceUrl, stagingData);
  } else {
    saveToStaging = await httpClient.post(stagingDeviceUrl, stagingData);
  }
  // then do same for tenant database
  let saveToTenant;
  const tenantDeviceUrl = endpoints.PROVISIONED_DEVICE_BY_SERIAL_NUMBER
    .replace('{serNum}', serialNumber);
  const getTenantResponse = await httpClient.get(tenantDeviceUrl);
  if (getTenantResponse.data) {
    saveToTenant = await httpClient.put(tenantDeviceUrl, data);
  } else {
    saveToTenant = await httpClient.post(tenantDeviceUrl, data);
  }
  return saveToTenant.status && saveToStaging.status;
};

export {
  getDevices, getDeviceBySN, saveDeviceBySN, getDeviceTenant, getDefaultOsaSensorSettings,
};
