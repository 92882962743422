import React from 'react';
import OSACard from './OSACard';
import TVCard from './TVCard';

const StatusList = (): JSX.Element => (
  <>
    <OSACard />
    <TVCard />
  </>
);

export default StatusList;
