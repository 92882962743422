import React, { useContext } from 'react';
import { DescriptionText } from 'shared/ui/device-list/list-item/model';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { SecuritySensor, SecureDisplayListViewProps } from '../../model';
import { matchesFilterCriteria } from '../../../../shared/services/utils';
import styles from './list-view.module.scss';
import { SearchContext } from '../../../app/Context';
import SecureDisplayListItem from './secure-display-list-item';

const AllSecuritySensors = ({ list }: SecureDisplayListViewProps): JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const filteredList = list
    .filter((item: SecuritySensor) => matchesFilterCriteria(item, searchCriteria));

  const getDescriptionBySensorData = (item: SecuritySensor): DescriptionText[] =>
    [
      { text: item.merchandise?.name || '', bold: true },
      { text: item.positionInStore?.name || '' },
    ];

  const getCodeByTVData = (item: SecuritySensor): string => item.labelCode;

  const renderSecuritySensorItem = (item: SecuritySensor): JSX.Element => (
    <div key={item.labelCode}>
      <SecureDisplayListItem
        description={getDescriptionBySensorData(item)}
        barcode={getCodeByTVData(item)}
        classId={item.classId}
      />
      <Divider />
    </div>
  );

  return (
    <Container className={styles.container}>
      {filteredList.map(renderSecuritySensorItem)}
    </Container>
  );
};

export default AllSecuritySensors;
