import httpClient from 'shared/services/http-client';
import { getStore, getTenant } from 'common/services/token.service';
import { SecuritySensor } from './model';

// endpoints
const ALL_SECURE_DISPLAYS_DATA = `/v1/model/tenants/{tenantId}/devices?classId=[onePod, alarm, vertical]&storeId={storeId}`;
const SECURE_DEVICE_SEND_COMMAND = `/v1/device/config/{classId}/{serNum}/`;

const COMMAND_TIMEOUT = 5000; // milliseconds

export const fetchSecureDisplaysData = async (): Promise<SecuritySensor[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = ALL_SECURE_DISPLAYS_DATA
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url);
  const items = response.data;
  return items;
};

export const toggleDeviceLock = async (
  serNum: string, classId: string, data: object): Promise<number> => {
  const tenant = getTenant();
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenant,
    },
    timeout: COMMAND_TIMEOUT,
  };
  const url = SECURE_DEVICE_SEND_COMMAND
    .replace('{classId}', classId)
    .replace('{serNum}', serNum);
  const response = await httpClient.post(url, data, updatedConfig);
  return response.status;
};

export const lockDevice = async (serNum: string, classId: string): Promise<number> =>
  toggleDeviceLock(serNum, classId, { isDeviceLocked: true });

export const unlockDevice = async (serNum: string, classId: string): Promise<number> =>
  toggleDeviceLock(serNum, classId, { isDeviceLocked: false });

export const silenceDevice = async (serNum: string, classId: string): Promise<number> => {
  const data = { alarmStop: true };
  const tenant = getTenant();
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenant,
    },
    timeout: COMMAND_TIMEOUT,
  };
  const url = SECURE_DEVICE_SEND_COMMAND
    .replace('{classId}', classId)
    .replace('{serNum}', serNum);
  const response = await httpClient.post(url, data, updatedConfig);
  return response.status;
};
