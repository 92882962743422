import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { OSASensor } from 'modules/osa/model';
import {
  isDisconnected, isIssue, isLowBattery, isProvisioned,
  matchesFilterCriteria,
} from 'modules/osa/osa-util';
import { ItemStatus, ListItemErrorStatus } from 'shared/ui/device-list/list-item/model';
import { ListViewProps } from './model';
import OSAListView, { OSAListViewItemData } from './list-view';
import { SearchContext } from '../../../app/Context';

const IssuesOSA = ({ list }: ListViewProps): JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const { formatMessage: i18n } = useIntl();
  const lowBatteryStr = i18n({ id: 'common.lowBattery' });
  const offlineStr = i18n({ id: 'common.offline' });
  const installPendingStr = i18n({ id: 'devices.installPending' });

  const compare = (a: OSASensor, b: OSASensor): number => {
    if (isDisconnected(a) && isDisconnected(b)) return 0;
    if (isDisconnected(a) && isLowBattery(b)) return -1;
    if (isDisconnected(a) && !isProvisioned(b)) return 1;
    if (!isProvisioned(a) && !isProvisioned(b)) return 0;
    if (!isProvisioned(a) && isDisconnected(b)) return -1;
    if (!isProvisioned(a) && isLowBattery(b)) return -1;
    if (isLowBattery(a) && !isProvisioned(b)) return 1;
    if (isLowBattery(a) && isDisconnected(b)) return 1;
    return 0;
  };

  const mapOSAtoListItemData = (items: OSASensor[]): OSAListViewItemData[] => items
    .filter((item: OSASensor) => isIssue(item)
      && matchesFilterCriteria(item, searchCriteria))
    .sort((a, b) => compare(a, b))
    .map((item: OSASensor) => {
      let statusText = '';
      let statusTextNumber = 0;
      if (isDisconnected(item)) {
        statusText = offlineStr;
        statusTextNumber += 1;
      } else if (!isProvisioned(item)) {
        statusText = installPendingStr;
        statusTextNumber += 1;
      } else if (isLowBattery(item) && statusTextNumber < 2) {
        statusText += (`${lowBatteryStr}`);
        statusTextNumber += 1;
      }
      const status: ItemStatus = {
        text: statusText,
        errorStatus: ListItemErrorStatus.warning,
      };
      return {
        OSAData: item,
        status,
      };
    });

  return <OSAListView list={mapOSAtoListItemData(list)} />;
};

export default IssuesOSA;
