import React, { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { DescriptionText } from 'shared/ui/device-list/list-item/model';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { TVPowerChangeResponse, TVSensor, TVState } from '../../model';
import { powerOffTV, powerOnTV, tvPowerStatus, matchesFilterCriteria, isPowerStateUnknown } from '../../tvSensor.service';
import { barcodeToSNFormat } from '../../../../shared/services/utils';
import styles from './list-view.module.scss';
import { TVPowerListViewProps } from './model';
import PowerListItem from './power-list-item';
import { SearchContext } from '../../../app/Context';

const compare = (a: TVSensor, b: TVSensor): number => {
  if (tvPowerStatus(a) === TVState.ON && tvPowerStatus(b) === TVState.OFF) return 1;
  if (tvPowerStatus(a) === TVState.OFF && tvPowerStatus(b) === TVState.ON) return -1;
  return 0;
};

const TVsOffAndOn = ({ list, refresh }: TVPowerListViewProps): JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const filteredList = list
    .filter((item: TVSensor) => !isPowerStateUnknown(item)
      && matchesFilterCriteria(item, searchCriteria))
    .sort((a, b) => compare(a, b));
  const [open, setOpen] = useState(false);

  const { formatMessage: i18n } = useIntl();
  const pleaseWaitStr = i18n({ id: 'common.pleaseWait' });

  const handleChange = async (name: string, checked: boolean): Promise<void> => {
    const serialNumber = barcodeToSNFormat(name);
    // initiate progress icon for 10 seconds
    setOpen(true);
    const response: TVPowerChangeResponse = checked
      ? await powerOnTV(serialNumber) : await powerOffTV(serialNumber);
    if (response) {
      refresh();
    }
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const getDescriptionByTVData = (item: TVSensor): DescriptionText[] =>
    [
      { text: item.merchandise?.name || '', bold: true },
      { text: item.positionInStore?.name || '' },
    ];

  const getToggleByTVData = (item: TVSensor): boolean => tvPowerStatus(item) === TVState.ON;

  const getCodeByTVData = (item: TVSensor): string => item.labelCode;

  const renderTVListItem = (item: TVSensor): JSX.Element => (
    <div key={item.labelCode}>
      <PowerListItem
        description={getDescriptionByTVData(item)}
        toggle={getToggleByTVData(item)}
        barcode={getCodeByTVData(item)}
        onChange={handleChange}
      />
      <Divider />
    </div>
  );

  return (
    <Container className={styles.container}>
      <Dialog open={open}>
        <>
          <DialogTitle id="loading-dialog">{pleaseWaitStr}</DialogTitle>
          <Paper elevation={0} className={styles.progressDialog}>
            <CircularProgress />
            <Button color="secondary" onClick={handleClose}>Close</Button>
          </Paper>
        </>
      </Dialog>
      {filteredList.map(renderTVListItem)}
    </Container>
  );
};

export default TVsOffAndOn;
