import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './index.module.scss';
import { useIntl } from 'react-intl';
import { Container, Button, Typography, OutlinedInput, Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { saveDeviceBySN } from '../devices.service';
import { convertUnitValues, getPositionDepthUnits, IMPERIAL, METRIC, getThresholdForSensors } from './constants';
import { SelectField } from './SelectField';
import './newDevicePreview.scss';
import { Device } from '../model';

interface NewDevicePreviewProps {
  scanMerchandise: () => void;
  selectPositionInStore: () => void;
  labelCodeValue: string;
  serialNum: string;
  deviceTypeValues: { type: string; display: string };
  merchCodeValue: string;
  merchNameValue: string;
  positionInStoreValue: string;
  positionEmptyDepthValue: number;
  onChange: (arg0: number) => void;
  positionLowWarningValue: string;
  onPositionLowWarningChange: (lowWarningChangeValue: string) => void;
  merchDeviceUpcValue?: string;
  brandValue?: string;
  departmentValue?: string;
}

function Alert(props: AlertProps): React.ReactElement {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NewDevicePreview = (props: NewDevicePreviewProps): JSX.Element => {
  const intl = useIntl();
  const { formatMessage: i18n } = intl;
  const positionInStoreStr = i18n({ id: 'common.positionInStore' });
  const positionDepthStr = i18n({ id: 'devices.positionDepth' });
  const merchandiseStr = i18n({ id: 'merchandise.merchandise' });
  const cancelStr = i18n({ id: 'common.cancel' });
  const saveStr = i18n({ id: 'common.save' });
  const serialNumberStr = i18n({ id: 'devices.serialNumber' });
  const successStr = i18n({ id: 'devices.success' });
  const invalidPositionDepthStr = i18n({ id: 'devices.invalidPositionDepth' });
  const saveFailedStr = i18n({ id: 'devices.saveFailed' });
  const positionLowWarningStr = i18n({ id: 'osaSettings.positionLowWarning' });
  const {
    labelCodeValue,
    serialNum,
    deviceTypeValues,
    merchNameValue,
    merchCodeValue,
    positionInStoreValue,
    positionEmptyDepthValue,
    positionLowWarningValue,
    merchDeviceUpcValue,
    brandValue,
    departmentValue,
  } = props;

  let merchandiseData = '';
  if (merchCodeValue && merchCodeValue.trim().length > 0) {
    merchandiseData = `${merchCodeValue} - `;
  }
  merchandiseData = `${merchandiseData}${merchNameValue}`;
  if (merchDeviceUpcValue && merchDeviceUpcValue.trim().length > 0) {
    merchandiseData = `${merchandiseData} - ${merchDeviceUpcValue}`;
  }
  const history = useHistory();
  const [error, setError] = useState('');

  const newDeviceData: Device = {
    tenantId: sessionStorage.tenant_key,
    storeId: sessionStorage.store_key,
    labelCode: labelCodeValue,
    serialNum,
    classId: deviceTypeValues.type,
    positionInStore: {
      name: positionInStoreValue,
      department: departmentValue,
    },
    merchandise: {
      code: merchCodeValue,
      name: merchNameValue,
      deviceUPC: merchDeviceUpcValue,
      brand: brandValue,
    },
    isDeleted: false,
  };

  if (deviceTypeValues.type === 'osa') {
    newDeviceData.positionEmptyDepthMm = positionEmptyDepthValue;
    newDeviceData.positionLowWarning = positionLowWarningValue;
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);

  const handleClose = (): void => {
    setOpenSnackbar(false);
  };

  const [positionDepthUnits, setPositionDepthUnits] = useState(METRIC);

  const handleUnitChange = (unitValue: string): void => {
    const depthVal = convertUnitValues(positionEmptyDepthValue, positionDepthUnits, unitValue);
    props.onChange(Math.round(depthVal));
    setPositionDepthUnits(unitValue);
  };

  const handlePositionLowWarningChange = (unitValue: string): void => {
    props.onPositionLowWarningChange(unitValue);
  };

  const save = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();
    if (positionDepthUnits === IMPERIAL) {
      newDeviceData.positionEmptyDepthMm = positionEmptyDepthValue * 25;
      if (positionEmptyDepthValue < 6 || positionEmptyDepthValue > 60) {
        setError(invalidPositionDepthStr);
        return;
      }
    }
    if (positionDepthUnits === METRIC) {
      if (positionEmptyDepthValue < 150 || positionEmptyDepthValue > 1500) {
        setError(invalidPositionDepthStr);
        return;
      }
    }
    // reformat s/n before running save so format matches what's in firestore.
    const response = await saveDeviceBySN(serialNum, newDeviceData);
    if (response === 200) {
      switch (deviceTypeValues.type) {
        case 'osa': history.push('/osa'); break;
        case 'tv': history.push('/tvs'); break;
        default: history.goBack(); break;
      }
      setOpenSnackbar(true);
    } else {
      setOpenErrorSnackbar(true);
      setTimeout(
        setOpenErrorSnackbar, 4000, false,
      );
    }
  };

  const scanMerchandise = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    props.scanMerchandise();
  };

  const selectPositionInStore = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    props.selectPositionInStore();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    props.onChange(parseInt(e.target.value.trim(), 10));
  };

  return (
    <Container>
      <div className="root-container">
        <Typography variant="caption" className="typography">
          {`${deviceTypeValues.display} ${serialNumberStr}:`}
        </Typography>
        <OutlinedInput
          disabled
          fullWidth
          value={labelCodeValue}
          className="outlined-Input"
        />
      </div>
      <div className="merchandise-container">
        <Typography variant="caption" className="typography">
          {`${merchandiseStr}:`}
        </Typography>
        <OutlinedInput
          disabled
          fullWidth
          value={merchandiseData}
          className="OutlinedInput"
          endAdornment={(
            <InputAdornment variant="filled" position="end">
              <IconButton
                size="small"
                onClick={scanMerchandise}
              >
                <EditIcon />
              </IconButton>
            </InputAdornment>
          )}
        />
      </div>
      <div className="merchandise-container">
        <Typography variant="caption" className="typography">
          {`${positionInStoreStr}:`}
        </Typography>
        <OutlinedInput
          disabled
          fullWidth
          value={positionInStoreValue}
          className="OutlinedInput"
          endAdornment={(
            <InputAdornment variant="filled" position="end">
              <IconButton
                size="small"
                onClick={selectPositionInStore}
              >
                <EditIcon />
              </IconButton>
            </InputAdornment>
          )}
        />
      </div>
      {deviceTypeValues.type === 'osa'
        ? (
          <>
            <div className="merchandise-container">
              <Typography variant="caption" className="typography">
                {`${positionDepthStr}:`}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <OutlinedInput
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    value={positionEmptyDepthValue}
                    className="OutlinedInput"
                  />
                </Grid>
                <Grid item xs={2}>
                  <SelectField
                    items={getPositionDepthUnits(intl)}
                    value={positionDepthUnits}
                    onChange={handleUnitChange}
                  />
                </Grid>
              </Grid>
              {error ? <Typography color="error">{invalidPositionDepthStr}</Typography> : null}
            </div>

            <div className="merchandise-bottom">
              <Typography variant="caption" className="typography">
                {`${positionLowWarningStr}:`}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SelectField
                    items={getThresholdForSensors()}
                    value={positionLowWarningValue}
                    onChange={handlePositionLowWarningChange}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        )
        : <div />}

      <div className="button-container">
        <Button
          variant="contained"
          color="secondary"
          className="merchandise-cancelButton"
          href="/"
        >
          {cancelStr}
        </Button>
        <Button
          disabled={!positionInStoreValue
            || ((!merchCodeValue) && (!merchDeviceUpcValue)) || !labelCodeValue}
          variant="contained"
          color="primary"
          className="merchandise-addButton"
          onClick={save}
        >
          {saveStr}
        </Button>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={handleClose}
          message={successStr}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openErrorSnackbar}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">{saveFailedStr}</Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

export default NewDevicePreview;
