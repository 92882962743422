import React from 'react';
import './index.module.scss';
import { useIntl } from 'react-intl';
import { Paper, Button, Typography, Grid } from '@material-ui/core';

interface ConfirmDeviceProps {
  nextStep: () => void;
  returnToPrevScreen: () => void;
  labelCodeValue: string;
  deviceTypeValue: { type: string; display: string };
}

const ConfirmDevice = (props: ConfirmDeviceProps): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const backStr = i18n({ id: 'devices.back' });
  const serialNumberStr = i18n({ id: 'devices.serialNumber' });
  const nextStr = i18n({ id: 'devices.next' });
  const { labelCodeValue, deviceTypeValue } = props;
  const newSensorIdentifiedStr = deviceTypeValue.type === 'unavailable'
    ? i18n({ id: 'common.offline' })
    : i18n({ id: 'devices.newSensorIdentified' }, { deviceType: deviceTypeValue.display });

  const next = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    props.nextStep();
  };

  const back = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    props.returnToPrevScreen();
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Paper component="form" elevation={0} style={{ marginTop: '10vw' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            {newSensorIdentifiedStr}
          </Typography>
        </Paper>
      </Grid>
      <Grid item>
        <Paper component="form" elevation={0} style={{ marginTop: '10vw' }}>
          <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {`${serialNumberStr}: ${labelCodeValue}`}
          </Typography>
        </Paper>
      </Grid>
      <Grid item>
        <div style={{ position: 'fixed', left: '0', bottom: '0' }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ display: 'inline-block', borderRadius: '0', width: '50vw', marginLeft: '0', marginRight: '0' }}
            onClick={back}
          >
            {backStr}
          </Button>
          <Button
            disabled={deviceTypeValue.type === 'unavailable'}
            variant="contained"
            color="primary"
            style={{ display: 'inline-block', borderRadius: '0', width: '50vw', marginLeft: '0', marginRight: '0' }}
            onClick={next}
          >
            {nextStr}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default ConfirmDevice;
