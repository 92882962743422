import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchOSAData } from '../../osa/osa.service';
import { OSACount, OSAWithCount } from '../../osa/model';

const defaultItemsCount: OSACount = {
  totalCount: 0,
  emptyCount: 0,
  offlineCount: 0,
  sensorsWithIssuesCount: 0,
};

const OSACard = (): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const emptyLowStockStr = i18n({ id: 'dashboard.emptyLowStock' });
  const issuesStr = i18n({ id: 'common.issues' });
  const viewAvailabilitySensorsStr = i18n({ id: 'dashboard.viewAvailabilitySensors' });

  const [osaSensorsCounts, setOsaSensorsCounts] = useState(defaultItemsCount);
  const [OSAIsLoading, setOSAIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const refreshSensorData = (): void => {
    setRefresh((prevState) => !prevState);
  };

  useEffect(() => {
    fetchOSAData().then((osaStatusData: OSAWithCount) => {
      if (osaStatusData && osaStatusData.count) {
        setOsaSensorsCounts(osaStatusData.count);
      }
      setOSAIsLoading(false);
    });
    const refreshInterval = Number(sessionStorage.refresh_key);
    const timeoutInterval = setTimeout(refreshSensorData, refreshInterval);
    return (): void => clearTimeout(timeoutInterval);
  }, [refresh]);

  const osaContent = osaSensorsCounts.totalCount > 0
    ? (
      <Card variant="outlined" style={{ width: '90vw', marginBottom: '5vw' }}>
        <CardContent>
          <Box display="flex" flexDirection="row" justifyContent="space-around">
            <Box>
              <Typography align="center">
                {osaSensorsCounts.emptyCount}
              </Typography>
              <Typography align="center">
                {emptyLowStockStr}
              </Typography>
            </Box>
            <Box>
              <Typography align="center">
                {osaSensorsCounts.sensorsWithIssuesCount}
              </Typography>
              <Typography align="center">
                {issuesStr}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <div style={{ position: 'relative', bottom: '0' }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ display: 'inline-block', borderRadius: '0', width: '100%', marginLeft: '0', marginRight: '0' }}
          >
            <Typography variant="overline" style={{ color: 'black' }}>
              <Link color="inherit" underline="none" href="/osa">
                {viewAvailabilitySensorsStr}
              </Link>
            </Typography>
          </Button>
        </div>
      </Card>
    ) : null;

  return (
    <>
      {
        OSAIsLoading
          ? (
            <div style={{ padding: '10vh' }}>
              <CircularProgress />
            </div>
          )
          : osaContent
      }
    </>
  );
};

export default OSACard;
