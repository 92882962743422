import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import './index.module.scss';
import { useIntl } from 'react-intl';
import { QuaggaJSResultObject } from '@ericblade/quagga2';
import ReactQuagga, { useQuagga } from './ReactQuagga';
import { Merchandise } from '../../merchandise/model';
import { getMerchanidseByDeviceUpc, getMerchandiseByCode } from '../../merchandise/merchandise.service';
import MerchandiseSelectDialog from './MerchandiseModal';

interface ScanDeviceBarcodeProps {
  nextStep: () => void;
  returnToPrevScreen: () => void;
  onChange: (arg0: string) => void;
  onFindMerchandise: (merchCode: string,
    merchName: string,
    merchUPC?: string,
    brand?: string) => void;
  merchandiseScanValue: string;
}

const ScanMerchandise = (props: ScanDeviceBarcodeProps): JSX.Element => {
  const { merchandiseScanValue } = props;
  const { formatMessage: i18n } = useIntl();
  const backStr = i18n({ id: 'devices.back' });
  const codeNotFoundStr = i18n({ id: 'devices.codeNotFound' }, { scannedCode: merchandiseScanValue });
  const enterCodeManuallyStr = i18n({ id: 'devices.enterMerchCodeManually' });
  const nameNotFoundStr = i18n({ id: 'devices.nameNotFound' });
  const nextStr = i18n({ id: 'devices.next' });
  const scannerNotActiveStr = i18n({ id: 'devices.scannerNotActive' });
  const scannerNotSupportedStr = i18n({ id: 'devices.scannerNotSupported' });
  const troubleScanningStr = i18n({ id: 'devices.troubleScanning' });

  const scannerSupported = useQuagga();
  const [scannerIsActive, setScannerIsActive] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [open, setOpen] = useState(false);
  const [allMerchandise, setAllMerchandise] = useState<Merchandise[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    props.onChange(e.target.value.trim());
  };

  const handleScan = (data: QuaggaJSResultObject): void => {
    props.onChange(data.codeResult.code);
  };

  const uniqueMerchandise = (merchandiseByCode: Merchandise[],
    merchandiseByDeviceUpc: Merchandise[]): Merchandise[] => {
    const merchandise = merchandiseByCode.concat(merchandiseByDeviceUpc);
    const unique: Merchandise[] = [];
    merchandise.map((x) => (unique.filter((a) => a.DOCUMENT_KEY
    === x.DOCUMENT_KEY).length > 0 ? null : unique.push(x)));
    return unique;
  };

  const next = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();
    let scannedMerchandiseObject: Merchandise[] = await getMerchandiseByCode(merchandiseScanValue);
    const merchandiseDeviceObject:
      Merchandise[] = await getMerchanidseByDeviceUpc(merchandiseScanValue);

    scannedMerchandiseObject = uniqueMerchandise(scannedMerchandiseObject, merchandiseDeviceObject);
    if (scannedMerchandiseObject.length === 1) {
      const { name, code, deviceUPC, brand } = scannedMerchandiseObject[0];
      props.onFindMerchandise(code || '',
        name || nameNotFoundStr, deviceUPC, brand);
      setScannerIsActive(false);
      props.onChange('');
      props.nextStep();
    } else if (scannedMerchandiseObject.length > 1) {
      setAllMerchandise(scannedMerchandiseObject);
      setOpen(true);
    } else {
      props.onFindMerchandise('', '');
      setErrorMessage(codeNotFoundStr);
    }
  };

  const back = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    props.returnToPrevScreen();
  };

  const handleClose = (value: Merchandise): void => {
    setOpen(false);
    const { code, name, deviceUPC, brand } = value;
    props.onFindMerchandise(code || '',
      name || nameNotFoundStr, deviceUPC, brand);
    setScannerIsActive(false);
    props.onChange('');
    props.nextStep();
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="stretch"
    >
      <Grid item>
        <Card variant="outlined" style={{ borderRadius: 0 }}>
          <CardActionArea>
            {scannerIsActive ? (
              <ReactQuagga
                onDetected={handleScan}
              />
            ) : (
              <Typography variant="body2" color="textSecondary" component="p" align="center">
                {scannerNotActiveStr}
              </Typography>
            )}
            <CardContent>
              {scannerSupported ? <div /> : (
                <Typography variant="body2" color="textSecondary" component="p" align="center">
                  {scannerNotSupportedStr}
                </Typography>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item>
        <div style={{ position: 'fixed', bottom: '0', textAlign: 'center', backgroundColor: 'white' }}>
          <Paper component="form" elevation={0} style={{ width: '100vw' }}>
            <Typography variant="body1">
              {troubleScanningStr}
            </Typography>
            <Typography variant="body1">
              {`${enterCodeManuallyStr}:`}
            </Typography>
            {errorMessage ? <Typography color="error">{errorMessage}</Typography> : null}
            <OutlinedInput
              id="merchandiseCode"
              type="text"
              value={merchandiseScanValue}
              onChange={handleChange}
              style={{ width: '80vw', height: '2rem', padding: 0, margin: '1rem 0', borderRadius: 0 }}
            />
          </Paper>
          <Button
            variant="contained"
            color="secondary"
            style={{ display: 'inline-block', borderRadius: '0', width: '50vw', marginLeft: '0', marginRight: '0' }}
            onClick={back}
          >
            {backStr}
          </Button>
          <Button
            disabled={!merchandiseScanValue}
            variant="contained"
            color="primary"
            style={{ display: 'inline-block', borderRadius: '0', width: '50vw', marginLeft: '0', marginRight: '0' }}
            onClick={next}
          >
            {nextStr}
          </Button>
        </div>
      </Grid>
      <MerchandiseSelectDialog allMerchandise={allMerchandise} open={open} onClose={handleClose} />
    </Grid>
  );
};

export default ScanMerchandise;
