import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { deleteTVSensor, fetchTVDevice } from '../tvSensor.service';
import { TVConfig, TVCurrentState, TVSensor, TVState } from '../model';
import TVDeviceViewDetails from './TVDeviceViewDetails';
import TVDeviceViewHistory from './TVDeviceViewHistory';
import TVDeviceViewTVData from './TVDeviceViewTVData';
import styles from './index.module.scss';
import ConfirmationDialog from '../../../shared/ui/Dialogs/ConfirmationDialog';

const initialConfig: TVConfig = {
  tvState: TVState.UNKNOWN,
  nextScheduledCommand: {
    desiredState: TVState.ON,
    isActive: false,
    day: '',
    hour: 0,
    minute: 0,
  },
};

const initialState: TVCurrentState = {
  powerChangeTimeISO: '',
  powerChangeTimeMs: 0,
  updateTimeISO: '1970-01-01T00:00:00.000Z',
  updateTimeMs: 0,
  batteryValue: 0,
};

const initialDevice: TVSensor = {
  classId: 'tv',
  labelCode: 'No code',
  merchandise: {
    code: '',
    name: '',
  },
  config: initialConfig,
  currentState: initialState,
};

const TVDeviceView = (): JSX.Element => {
  const { deviceSerNum } = useParams();
  const { formatMessage: i18n } = useIntl();
  const detailsStr = i18n({ id: 'common.details' });
  const historyStr = i18n({ id: 'common.history' });
  const tvDataStr = i18n({ id: 'devices.tvData' });
  const [tabIndex, setTabIndex] = useState(0);
  const [TVDevice, setTVDevice] = useState(initialDevice);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteModalPopup, setDeleteModalPopup] = useState(false);
  const confirmStr = i18n({ id: 'common.confirm' });
  const cancelStr = i18n({ id: 'common.cancel' });
  const dialogContentStr = i18n({ id: 'common.deleteConfirmationMessage' }, { name: TVDevice.merchandise?.name });
  const dialogErrorMessageStr = i18n({ id: 'common.deleteDialogErrorMessage' }, { name: 'TV' });
  const dialogOkButtonStr = i18n({ id: 'common.ok' });

  useEffect(() => {
    fetchTVDevice(deviceSerNum).then((item) => {
      setTVDevice(item);
      setIsLoading(false);
    });
  }, [deviceSerNum, refresh]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTabIndex(newValue);
  };

  const back = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    window.location.href = '/tvs';
  };

  const refreshSerialNumber = (): void => {
    setTimeout(() => {
      setRefresh(false);
    }, 2000);
    setRefresh(true);
  };

  const renderTVSensor = (visibleIndex: number): JSX.Element => {
    switch (visibleIndex) {
      case 1:
        return <TVDeviceViewTVData sensorData={TVDevice} refresh={refreshSerialNumber} />;
      case 2:
        return <TVDeviceViewHistory />;
      default:
        return (
          <TVDeviceViewDetails
            sensorData={TVDevice}
            refresh={refreshSerialNumber}
          />
        );
    }
  };

  const handleOpenDialog = (): void => {
    setConfirmDialog(true);
  };

  const handleCloseDialog = (): void => {
    setConfirmDialog(false);
  };

  const openDeleteFailureModal = (): void => {
    setDeleteModalPopup(true);
  };

  const closeDeleteFailureModal = (): void => {
    setDeleteModalPopup(false);
  };

  const handleDeleteConfirm = async (): Promise<void> => {
    setConfirmDialog(false);
    if (deviceSerNum !== undefined) {
      const resultOfDelete: string = await deleteTVSensor(deviceSerNum);
      if (resultOfDelete === 'OK') {
        window.location.href = '/tvs';
      } else {
        openDeleteFailureModal();
      }
    }
  };

  return (isLoading
    ? (
      <div style={{ marginTop: '50vh' }}>
        <CircularProgress />
      </div>
    )
    : (
      <>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ width: '95%' }}
        >
          <IconButton onClick={back}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">{TVDevice.merchandise?.name}</Typography>
          <div>
            <Link
              to={{
                pathname: '/devices/scan',
                state: {
                  labelCode: TVDevice.labelCode,
                  serialNumber: TVDevice.serialNum,
                  step: 3,
                  deviceType: {
                    type: 'tv',
                    display: 'TV Manager',
                  },
                  merchandise: TVDevice.merchandise,
                  positionInStore: TVDevice.positionInStore,
                },
              }}
            >
              <EditIcon color="secondary" />
            </Link>
            <DeleteIcon color="secondary" id="deleteTV" onClick={handleOpenDialog} className={styles['delete-icon']} />
          </div>
          <ConfirmationDialog
            open={confirmDialog}
            handleClose={handleCloseDialog}
            handleAgree={handleDeleteConfirm}
            contentText={dialogContentStr}
            cancelLabel={cancelStr}
            confirmLabel={confirmStr}
          />
          <ConfirmationDialog
            open={deleteModalPopup}
            handleAgree={closeDeleteFailureModal}
            contentText={dialogErrorMessageStr}
            confirmLabel={dialogOkButtonStr}
          />
        </Box>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          variant="fullWidth"
          onChange={handleChange}
          aria-label="TV Manager Tabs"
          className={styles['tabs-container']}
        >
          <Tab data-testid="tv-details-tab" label={detailsStr} />
          <Tab data-testid="tv-data-tab" label={tvDataStr} />
          <Tab disabled data-testid="tv-history-tab" label={historyStr} />
        </Tabs>
        {renderTVSensor(tabIndex)}
      </>
    )
  );
};

export default TVDeviceView;
