import React from 'react';
import { useIntl } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Merchandise } from '../../merchandise/model';

interface MerchandiseModal {
  onClose: (merchandise: Merchandise) => void;
  open: boolean;
  allMerchandise: Array<Merchandise>;
}

const MerchandiseSelectDialog = (props: MerchandiseModal): JSX.Element => {
  const { formatMessage: i18n } = useIntl();
  const selectMerchandise = i18n({ id: 'devices.selectMerchandise' });

  const { onClose, open, allMerchandise } = props;

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{selectMerchandise}</DialogTitle>
      <List>
        {allMerchandise.map((merchandise) => (
          <ListItem
            button
            onClick={(): void => onClose(merchandise)}
            key={merchandise.DOCUMENT_KEY}
          >
            <ListItemText primary={merchandise.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default MerchandiseSelectDialog;
