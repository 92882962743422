/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
import {
  setRegistrationToken,
  getUser,
} from 'common/services/token.service';
import { sendRegistrationTokenToServer } from 'modules/login/login.service';
import firebase from './firebase';

const requestUserPermission = async (): Promise<any> => {
  const messaging = firebase.messaging();
  try {
    await messaging.requestPermission();
    const newToken = await messaging.getToken();
    const userName = getUser();

    setRegistrationToken(newToken);
    if (userName) {
      sendRegistrationTokenToServer(userName, newToken);
    }
  } catch (error) {
    console.log(error);
  }
};

const initializePush = async (registration: ServiceWorkerRegistration): Promise<any> => {
  let serviceWorker;
  if (registration.installing) {
    serviceWorker = registration.installing;
  } else if (registration.waiting) {
    serviceWorker = registration.waiting;
  } else if (registration.active) {
    serviceWorker = registration.active;
  }

  if (serviceWorker) {
    if (serviceWorker.state === 'activated') {
      // If push subscription wasnt done yet have to do here
      requestUserPermission();
    }
    serviceWorker.addEventListener('statechange', async (e) => {
      if (e.target && (e.target as ServiceWorker).state === 'activated') {
        // use pushManger for subscribing here.
        requestUserPermission();
      }
    });
  }
};

export default function registerServiceWorker(): void {
  const messagingSenderId = process.env.REACT_APP_MESSAGINGID;
  const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js?messagingSenderId=${messagingSenderId}`;
  const messaging = firebase.messaging();

  window.addEventListener('load', async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(swUrl);
        messaging.useServiceWorker(registration);
        await initializePush(registration);
      } catch (error) {
        console.log('Service Worker Error', error);
      }
    }
  });
}
