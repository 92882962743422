import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { ListViewProps } from './model';
import { TVSensor, TVState } from '../../model';
import TVListView, { TVListViewItemData } from './list-view';
import {
  isDisconnected,
  tvPowerStatus,
  isLowBattery,
  useIntlOffline,
  isProvisioned,
  isPowerStateUnknown,
  matchesFilterCriteria,
} from '../../tvSensor.service';
import { ListItemErrorStatus } from '../../../../shared/ui/device-list/list-item/model';
import { intlCompareStrings } from '../../../../shared/services/utils';
import { SearchContext } from '../../../app/Context';

const AllTVs = ({ list }: ListViewProps): JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const { formatMessage: i18n } = useIntl();
  const offineStr = useIntlOffline();
  const installPendingStr = i18n({ id: 'devices.installPending' });
  const offStr = i18n({ id: 'devices.off' });
  const lowBatteryStr = i18n({ id: 'common.lowBattery' });
  const powerStateUnknownStr = i18n({ id: 'devices.powerStateUnknown' });

  const mapTVtoListItemData = (items: TVSensor[]): TVListViewItemData[] => items
    .filter((item: TVSensor) => matchesFilterCriteria(item, searchCriteria))
    .sort((item1, item2) =>
      intlCompareStrings(item1.merchandise.name, item2.merchandise.name))
    .map((item: TVSensor) => {
      let statusText = '';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let statusTextNumber = 0;
      if (isDisconnected(item)) {
        statusText = offineStr;
      } else if (!isProvisioned(item)) {
        statusText = installPendingStr;
      } else {
        if (isPowerStateUnknown(item)) {
          statusText += `${powerStateUnknownStr}\n`;
          statusTextNumber += 1;
        }
        if (tvPowerStatus(item) === TVState.OFF) {
          statusText += `${offStr}\n`;
          statusTextNumber += 1;
        }
        if (isLowBattery(item) && statusTextNumber < 2) {
          statusText += (`${lowBatteryStr}\n`);
          statusTextNumber += 1;
        }
        // FIXME add missing issues
        /* if (another error type(item) && statusTextNumber < 2) {
          statusText.concat(`\n${errorFunction(value)}`)
          statusTextNumber += 1;
        } */
      }
      const status = {
        text: statusText,
        errorStatus: (isDisconnected(item) || tvPowerStatus(item) !== TVState.ON)
          ? ListItemErrorStatus.warning
          : ListItemErrorStatus.None,
      };
      return {
        TVData: item,
        status,
      };
    });

  return <TVListView list={mapTVtoListItemData(list)} />;
};

export default AllTVs;
